import styled from "styled-components";

const VehicleResultStyle = styled.div`
  border: none;
  background: white;
  padding: 1em;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  div.vehicle_holder {
    display: flex;
    justify-content: space-around;
  }
  .terms {
    justify-self: start;
    font-weight: bold;
  }
  @media only screen and (max-width: 1050px) {
    transition: width 0.5s;
    width: 65vw;
  }
`;

export default VehicleResultStyle;
