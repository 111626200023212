import styled from "styled-components";

const ButtonStyle = styled.button`
  border: none;
  padding: 0.5em;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  background: ${(props) =>
    props.background !== "" ? props.background : "rgb(24 119 242)"};
  color: white;
  font-size: 0.9375rem;
  font-weight: 600;
  margin: 0.5em;
  button:focus {
    outline: 0;
  }
  button:hover {
    cursor: pointer;
  }
`;
export { ButtonStyle };
