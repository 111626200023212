import axios from "axios";

const validateTransferData = (transferData) => {
  return new Promise((resolve, reject) => {
    let final = transferData;
    // final = { ...final, EMAIL: userInfo.email.toUpperCase() };
    //console.log(final);
    final.PLATE = final.PLATE.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
    if (
      final.REASON === "" ||
      final.MARKET === "" ||
      final.DEPT === "" ||
      final.DRIVER_FIRST_NAME === "" ||
      final.DRIVER_LAST_NAME === "" ||
      final.GEO_STATUS === "" ||
      final.VIN === ""
    ) {
      resolve("Can not leave any fields blank (besides Plate)");
    } else {
      resolve(true);
    }
  }) 
}

const submitVehicleTransfer = (transferData,  setSuccess, userInfo) => {
  return new Promise(async(resolve, reject) => {
    //api call
    let final = transferData;
  
    final = { ...final, EMAIL: userInfo.email.toUpperCase() };
    console.log(final);

    axios
      .post(
        "https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/request_transfer",
        {
          REQUEST: final,
        }
      )
      .then((i) => {
        setSuccess(true);
        //console.log(final);

        axios
          .get(
            `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_all`
          )
          .then((v) => {
            setTimeout(() => {
              setSuccess(false);
              resolve(v.data.vehicle)
            }, 2000);
          });
      })
      .catch((e) => {
        reject("Some error happened (E10), please try again");
        // setIsDisabled(false);
      });
    
  })
};

export { validateTransferData, submitVehicleTransfer }