import styled from "styled-components";

const AddVehicleStyle = styled.div`
  display: grid;

  .header {
    font-weight: bolder;
  }
  
  .dropdowns {
    grid-column: 1 / 2;
  }

  .dropdown {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  .input-fields {
    grid-column: 2 / -1;
  }

  .input-field {
    width: 200px;
    margin: 5px;
  }

  .submitButton > button {
    width: 100px;
  }

  .vehicle-form {

  }

  .input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

`;

export default AddVehicleStyle;
