import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Select,
  TextField,
  MenuItem,
  Icon,
} from "@material-ui/core";

// Needs to be 1:1 for headers and rows
export default function GenericTable({ headers, rows }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead style={{ backgroundColor: "#369" }}>
          <TableRow>
            {headers.map((el) => {
              return (
                <TableCell
                  key={el}
                  className="header"
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  {el}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((el, i) => {
              return (
                <TableRow key={el + i + Math.random()}>
                  {headers.map((obj) => {
                    return (
                      <TableCell key={el[obj] + Math.random()}>
                        {el[obj]}
                        {/* test */}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
