import React, { useState } from "react";
import { motion } from "framer-motion";
import { Context } from "../../App";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { TableStyle } from "./styles";
import { firstCharCap, numberWithCommas } from "../../util";
const moment = require("moment");

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "65vh",
  },
});

const DynamicTable = ({ headers, rows, keyValue, ...props }) => {
  // const {

  // } = useContext(Context);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  const classes = useStyles();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <TableStyle>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((i) => {
                  return (
                    <TableCell key={i} className="head" align="center">
                      {i}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((v) => {
                  return (
                    <TableRow
                      key={v[keyValue]}
                      id={v[keyValue]}
                      onClick={props.clickRow}
                    >
                      {Object.keys(v).map((u) => {
                        return (
                          <TableCell key={u} className="bigFont" align="center">
                            {u === "status" ? (
                              <span className={v[u]}>{v[u]}</span>
                            ) : (
                              v[u]
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableStyle>
    </motion.div>
  );
};

export default DynamicTable;
