import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../../App";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";

import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import "../Reports.css";
import GenericTable from "../GenericTable";

export default function TerminationsReport() {
  const { setOpenReport, termReports, setTermReports } = useContext(Context);

  const headers = [
    "vehicle_id",
    "market",
    "department",
    "vin",
    "make",
    "model",
    "year",
    "plate",
    "fleet_first",
    "fleet_last",
    "driver_pay_id",
    "waldo_first",
    "waldo_last",
    "term_date",
    "active_status",
  ];

  const formatCsv = (rows) => {
    const rowData = rows.map((row) => {
      return Object.values(row);
    });
    console.log([headers, ...rowData]);
    return [headers, rowData];
  };

  const getTerminationReports = async () => {
    try {
      const res = await axios.post(
        "https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_reports",
        { report: "termination" }
      );
      console.log("term reports", res.data);
      setTermReports(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTerminationReports();
  }, []);

  return (
    <section>
      <header className="terminations-header">
        <ButtonCustom onClick={() => setOpenReport("")}>Back</ButtonCustom>
        <h1>Terminations Report</h1>

        <CSVLink data={termReports}>Export to Excel</CSVLink>
      </header>
      <article className="terminations-table">
        <GenericTable headers={headers} rows={termReports || []} />
      </article>
    </section>
  );
}
