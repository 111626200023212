import styled from "styled-components";

const SearchStyle = styled.div`
  display: grid;
  text-align: center;
  grid-template-areas:
    "head head head head head"
    ". b b b ."
    ". . c . ."
    ". . d . ."
    ". button button button .";

  .MuiAutocomplete-root {
    width: 250px;
  }
  .auto {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-area: c;
    margin-bottom: 1em;
  }
  .auto2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-area: d;
  }
  .header {
    grid-area: head;
    font-weight: bold;
  }
  .breaker {
    grid-area: b;
    width: 95%;
  }
  .submitButton {
    grid-area: button;
  }
  .submitButton > button {
    width: 50%;
  }
  @media only screen and (max-width: 850px) {
    .auto {
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 0em;
    }
    .auto2 {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;

export default SearchStyle;
