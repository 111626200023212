const firstCharCap = (text) => {

  if (!text) return ''


  return text
    .toLowerCase()
    .split(" ")
    .map((s) => {
      if (s !== "oz" && s !== "oz.") {
        return s.charAt(0).toUpperCase() + s.substring(1);
      } else {
        return s;
      }
    })
    .join(" ");
};
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

module.exports = { firstCharCap, numberWithCommas };
