import styled from "styled-components";

export const StyledLoadPage = styled.div`
  min-width: 200vw;
  min-height: 200vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
`;
