import styled from "styled-components";

export const StyledHRPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 100%;
  button.mainpage {
    margin: 1em;
    border: none;
    padding: 1.5em;
    font-size: 16px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-weight: bold;
    width: 300px;
    text-transform: uppercase;
  }
  button.mainpage:hover {
    cursor: pointer;
  }
  /* .manager .MuiFormControl-root input {
    width: 200px;
    margin-top: 5em;
  } */

  .chip_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search_button {
    padding: 1em;
    margin: 1em;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #2471a3;
    color: white;
  }
  .card {
    padding: 1em;
    margin: 1em;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .reason {
    border: solid;
    padding: 0.2em 0.5em 0.2em 0.5em;
    margin: 0.5em;
    border-radius: 7px;
    color: white;
    background-color: #2471a3;
    font-weight: bold;
  }
  .info {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    border: none;
    width: 50%;
    border-radius: 7px;
    font-weight: bold;
    color: white;
    background-color: rgb(33, 40, 62);
  }
  .button_container {
    display: flex;
  }
  h3 {
    text-decoration: underline;
  }
  .approve {
    padding: 1em;
    margin: 0.5em;
    border: none;
    outline: none;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-weight: bold;
    background: #25d366;
    color: white;
  }
  .reject {
    padding: 1em;
    margin: 0.5em;
    border: none;
    outline: none;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-weight: bold;
    background: #ff5a5f;
    color: white;
  }
  .info_granular {
    align-self: end;
    justify-self: start;
  }
  label {
    justify-self: end;
    align-self: end;
  }
  .grid_contain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
  }

  button.transfer {
    color: white;
    background: rgb(33, 40, 62);
  }
  button.repair {
    color: white;
    background: #878ea4;
  }
  button.inspection {
    color: white;
    background: rgb(33, 40, 62);
  }
  .add_filter {
    padding: 0.5em;
    border-radius: 5px;
    background-color: #3e4b5a;
    color: white;

    font-weight: bold;
    font-size: 14px;
  }

  .clear_filter {
    padding: 0.5em;
    border-radius: 5px;
    background-color: #3e4b5a;
    color: white;

    font-weight: bold;
    font-size: 14px;
  }
  .add {
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    padding: 1em;
    margin: 1em;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: #2471a3;
    border: none;
    outline: none;
  }
  .driver {
    border-bottom: solid thin;
    width: 90%;
  }
  button:hover {
    cursor: pointer;
  }

  input.search {
    margin: 1em;
    padding: 1em;
    font-size: 14px;
    border: solid thin;
  }
  .name_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .license_contain {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search_comp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  div.new_mvr {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .MuiInputBase-root {
    margin: 0.5em;
  }

  .MuiAutocomplete-root {
    margin: 0.5em;
  }
  div.name_container {
    margin: 0.2em;
  }
  textarea {
    resize: none;
    margin: 0.5em;
    margin-bottom: 1em;
  }
  div.auto_contain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  div.finalStep {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  div.outter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h3.name {
    padding: 0.5em;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    color: white;
    background: #3e4b5a;
    border: none;
    outline: none;
    width: 75%;
  }
  .submit {
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    padding: 1em;
    margin: 1em;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: #25d366;
    border: none;
    outline: none;
  }
  .cancel {
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    padding: 1em;
    margin: 1em;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: #ff5a5f;
    border: none;
    outline: none;
  }
  h3.title {
    border: none;
    padding: 1em;
    border-radius: 5px;
    margin: 0.5em;
    width: 40%;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background-color: rgb(33, 40, 62);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .filter_select {
    padding: 1em;
    background-color: #3e4b5a;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
  }
  .chip_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search_button {
    padding: 1em;
    margin: 1em;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #2471a3;
    color: white;
  }
  @media only screen and (max-width: 1500px) {
    .grid_contain {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 90%;
    }
  }
  @media only screen and (max-width: 1000px) {
    .grid_contain {
      margin-top: 3em;
      display: grid;
      grid-template-columns: 1fr;
      width: 90%;
    }
  }
`;
