import styled from "styled-components";

export const StyledAccidents = styled.div`

  .nav-container {
    display: flex;

  }

  .accident-nav {
    background-color: #ffffff;
  }

  .right-side {
    display: flex;
    just: flex-end;
  }

  #driver-search {
    width: 300px;
  }
`