import React, { useContext } from "react";
import Home from "../../Component/Icons/home.svg";
import Truck from "../../Component/Icons/truck.svg";
import Chart from "../../Component/Icons/chart.svg";
import Logs from "../../Component/Icons/log.svg";
import Exchange from "../../Component/Icons/exchange.svg";
import { StyledSidebar } from "./styles";
import { motion } from "framer-motion";
import { Context } from "../../App";

const FloatSidebar = ({ permission, ...props }) => {
  const { navValues, setNavValues, resetNav, setVehicleResults } = useContext(
    Context
  );

  const navClick = (e) => {
    e.preventDefault();
    let reset = resetNav();
    if (e.currentTarget.id !== "inventory") {
      setVehicleResults([]);
    }
    setNavValues({ ...reset, [e.currentTarget.id]: true });
  };

  return (
    <StyledSidebar>
      <motion.div
        initial={{
          opacity: 0,
          x: -150,
          y: -100,
        }}
        animate={{
          opacity: 1,
          x: 0,
          y: -100,
        }}
        transition={{ duration: 0.5 }}
      >
        <div className="sidebar">
          <ul>
            {
              permission === 'ADMIN_USER' &&
              <li
                className={navValues.master_dash ? "active" : ""}
                id="master_dash"
                onClick={navClick}
              >
                <img
                  className="icon"
                  src={Home}
                  alt="home icon"
                  style={{ height: "40px" }}
                />
                <p>Master Dash</p>
              </li>
            }
            <li
              className={navValues.inventory ? "active" : ""}
              id="inventory"
              onClick={navClick}
            >
              <img
                className="icon"
                src={Truck}
                alt="fleet icon"
                style={{ height: "40px" }}
              />
              <p>Inventory</p>
            </li>
            {
              permission === 'ADMIN_USER' && 
              <li
              className={navValues.addVehicle ? "active" : ""}
              id="addVehicle"
              onClick={navClick}
              >
                <img
                  className="icon"
                  src={Truck}
                  alt="fleet icon"
                  style={{ height: "40px" }}
                />
                <p>New Vehicle</p>
              </li>
            }
            {
            permission === 'ADMIN_USER' && 
            <li
              className={navValues.editVehicle ? "active" : ""}
              id="editVehicle"
              onClick={navClick}
            >
              <img
                className="icon"
                src={Truck}
                alt="fleet icon"
                style={{ height: "40px" }}
              />
              <p>Edit Vehicle</p>
            </li>
            }
            {permission === 'ADMIN_USER' && <li
              className={navValues.accident ? "active" : ""}
              id="accident"
              onClick={navClick}
            >
              <img
                className="icon"
                src={Logs}
                alt="fleet icon"
                style={{ height: "40px" }}
              />
              <p>Accident Reports</p>
            </li>
            }
            <li
              className={navValues.transfer ? "active" : ""}
              id="transfer"
              onClick={navClick}
            >
              <img
                className="icon"
                src={Exchange}
                alt="fleet icon"
                style={{ height: "40px" }}
              />
              <p>Transfer</p>
            </li>
            {
              permission === 'ADMIN_USER' && 
              <li
                className={navValues.reports ? "active" : ""}
                id="reports"
                onClick={navClick}
              >
                <img
                  className="icon"
                  src={Chart}
                  alt="report icon"
                  style={{ height: "40px" }}
                />
                <p>Reports</p>
              </li>
            }
            {
              permission === 'ADMIN_USER' &&
              <li
                className={navValues.logs ? "active" : ""}
                id="logs"
                onClick={navClick}
              >
                <img
                  className="icon"
                  src={Logs}
                  alt="report icon"
                  style={{ height: "40px" }}
                />
                <p>Logs</p>
              </li>
            }
          </ul>
        </div>
      </motion.div>
    </StyledSidebar>
  );
};

export default FloatSidebar;
