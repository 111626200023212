import styled from "styled-components";

export const StyledSidebar = styled.div`
  grid-area: side;
  min-width: 10vw;
  .sidebar {
    border: none;
    margin-top: 1em;
    padding: 0.5em;
    text-align: start;
    position: fixed;
    top: 100px;
    margin-left: 0.5em;
    background: white;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  }
  ul {
    list-style-type: none;
    padding: 0em;
  }
  ul:hover {
    cursor: pointer;
  }
  li {
    /* border: solid; */

    font-size: 0.9375rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 0.5em;
  }
  .active {
    border-right: solid;
    border-color: rgb(24 119 242);
  }
  img.icon {
    padding-right: 0.5em;
  }
`;