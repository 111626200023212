import React, { useContext } from "react";
import { SingleVehicleStyle } from "./styles";
import GoogleMapReact from "google-map-react";
import { motion } from "framer-motion";
import Pin from "../Icons/Pin.svg";
import f350 from "../../images/f350_ford.jpg";
import { Context } from "../../App";
import Avatar from "react-avatar";
import ReactTooltip from "react-tooltip";
const SingleVehicle = ({ ...props }) => {
  const { vehicleLocations, singleVehicle } = useContext(Context);
  return (
    <SingleVehicleStyle>
      <img
        src={f350}
        style={{ height: "100%" }}
        alt="placeholder vehicle"
        className="vehicleImage card imgCard"
      />

      {/* <Avatar
        name="Foo bar"
        className="vehicleImage"
        size="100%"
        round="20px"
        src={f350}
      /> */}

      <div className="vehicleInfo card">
        <p className="custom_Label">
          <span className="bubble">Vehicle ID</span>
        </p>
        <p className="custom_Info">{singleVehicle[0].VEHICLE_ID}</p>
        <p className="custom_Label">
          <span className="bubble">VIN</span>
        </p>
        <p className="custom_Info">{singleVehicle[0].VIN}</p>
        <p className="custom_Label">
          <span className="bubble">Plate</span>
        </p>
        <p className="custom_Info">{singleVehicle[0].PLATE}</p>
        <p className="custom_Label">
          <span className="bubble">Driver</span>
        </p>
        <p className="custom_Info">{`${singleVehicle[0].DRIVER_FIRST_NAME} ${singleVehicle[0].DRIVER_LAST_NAME}`}</p>
      </div>

      <div className="vehicleDetails card">
        <div className="first">
          <p className="custom_Label">
            <span className="bubble">Market</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].MARKET}</p>
          <p className="custom_Label">
            <span className="bubble">Department</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].DEPT}</p>
          <p className="custom_Label">
            <span className="bubble">Type</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].TYPE}</p>
        </div>
        <div className="first">
          <p className="custom_Label">
            <span className="bubble">Make</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].MAKE}</p>
          <p className="custom_Label">
            <span className="bubble">Model</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].MODEL}</p>
          <p className="custom_Label">
            <span className="bubble">Year</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].YEAR}</p>
        </div>
        <div className="first">
          <p className="custom_Label">
            <span className="bubble">Ext. Color</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].EXT_COLOR}</p>
          <p className="custom_Label">
            <span className="bubble">Fuel Type</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].FUEL_TYPE}</p>
          <p className="custom_Label">
            <span className="bubble">Fuel Capacity</span>
          </p>
          <p className="custom_Info">{singleVehicle[0].FUEL_CAPACITY}</p>
        </div>
      </div>

      <div className="vehicleMap card">
        {vehicleLocations.length > 0 && (
          <p className="custom_Label help">
            <span className="bubble" data-tip data-for="happyFace">
              {vehicleLocations[0].FORMATTED_ADDRESS}
              <ReactTooltip id="happyFace" type="dark">
                <span>
                  {`Last Active Date: ${vehicleLocations[0].LAST_ACTIVE_DATE}`}
                </span>

                {/* <span>
                  {`Geotab Status:
                  ${
                    vehicleLocations[0].IS_DEVICE_COMMUNICATING === "TRUE"
                      ? "Online"
                      : vehicleLocations[0].IS_DEVICE_COMMUNICATING === "FALSE"
                      ? "Offline"
                      : "NO GEOTAB"
                  }`}
                </span> */}
              </ReactTooltip>
            </span>
          </p>
        )}

        <div
          style={{
            height: "30vh",
            width: "100%",
          }}
        >
          {vehicleLocations.length > 0 &&
          vehicleLocations[0].LAT !== "NO GEOTAB" ? (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8",
              }}
              defaultCenter={{
                lat: vehicleLocations[0] ? +vehicleLocations[0].LAT : 0,
                lng: vehicleLocations[0] ? +vehicleLocations[0].LNG : 0,
              }}
              defaultZoom={16}
            >
              {vehicleLocations.map((i) => {
                return (
                  <img
                    src={Pin}
                    alt="pin icon"
                    style={{ height: "50px" }}
                    lat={+i.LAT}
                    lng={+i.LNG}
                  />
                );
              })}
            </GoogleMapReact>
          ) : (
            <div style={{ textAlign: "center" }}>NO GEOTAB</div>
          )}
        </div>
      </div>
    </SingleVehicleStyle>
  );
};

export default SingleVehicle;
