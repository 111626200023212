import React from "react";
import SuccessStyle from "./styles";

const Success = props => {
  return (
    <SuccessStyle>
      <div className="success-checkmark">
        <div className="check-icon">
          <span className="icon-line line-tip"></span>
          <span className="icon-line line-long"></span>
          <div className="icon-circle"></div>
          <div className="icon-fix"></div>
        </div>
      </div>
    </SuccessStyle>
  );
};

export default Success;
