import React, { useState, useContext, useEffect } from "react";
import { StyledMainPage } from "./styles";
import Success from "../../Component/Success/Success";
import { Context } from "../../App";
import Modal from "../../Component/Modal/Modal";
import { motion } from "framer-motion";
import axios from "axios";
import TransferForm from "../../Container/TransferForm/TransferForm";
import MainEntry from "../../Component/MainEntry/MainEntry";
import VehicleResult from "../../Component/VehicleResult/VehicleResult";
import Loader from "../../Component/Loader/Loader";
import LoaderHolder from "../../Container/LoaderHolder/LoaderHolder";
import AddVehicle from "../../Component/AddVehicle/AddVehicle";
import MainEntryStyle from "../../Component/MainEntry/styles";

const MainPage = ({ ...props }) => {
  const {
    success,
    permission,
    vehicleResults,
    setError,
    setFilterValues,
    vLoad,
    navValues,
  } = useContext(Context);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (
      permission === "ADMIN_USER" ||
      permission === "USER" ||
      permission === "USER_HR"
    ) {
      getSelect();
    }
  }, [permission]);

  const getSelect = async () => {
    try {
      let filter = await axios
        .get(
          `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_vehicle_select`
        )
        .then((res) => res.data.data);
      !filter.DEPT.includes("SPARE") && filter.DEPT.push("SPARE");
      setFilterValues(filter);
    } catch (e) {
      setError("Could not retrieve filter values");
    }
  };
  const transferClick = (e) => {
    e.preventDefault();
    setModal(true);
  };

  const adminClick = (e) => {
    e.preventDefault();
    props.history.push("/admin");
  };

  const hrClick = (e) => {
    e.preventDefault();
    props.history.push("/mvr");
  };

  const renderedUserHRView = (
    <button onClick={hrClick} className="mainpage inspection">
      MVR
    </button>
  );

  const renderedAdminUserView = () => {
    // Inventory
    if (navValues.inventory) {
      if (!vehicleResults.length) {
        return <MainEntry type="inventory" />;
      } else return <VehicleResult />;
    }

    // Add Vehicle
    else if (navValues.addVehicle) return <MainEntry type="addVehicle" />;
    else if (navValues.accident) return <MainEntry type="accident" />;
    //Edit Vehicle
    else if (navValues.editVehicle) return <MainEntry type="editVehicle" />;
    // Logs
    else if (navValues.logs) return <MainEntry type="logs" />;
    // Reports
    else if (navValues.reports) return <MainEntry type="reports" />;
    // Transfer
    else if (navValues.transfer)
      return (
        <button onClick={transferClick} className="mainpage transfer">
          Transfer
        </button>
      );
    // TODO routes
    else
      return (
        <motion.div
          initial={{
            opacity: 0,
            x: 50,
            y: -250,
          }}
          animate={{
            opacity: 1,
            x: 0,
            y: 0,
          }}
          transition={{ duration: 1 }}
        >
          <MainEntryStyle>
            <p>To Be Added</p>
          </MainEntryStyle>
        </motion.div>
      );
  };

  const renderedUserView = () => {
    if (navValues.inventory) {
      if (!vehicleResults.length) {
        return <MainEntry type="inventory" />;
      } else return <VehicleResult />;
    } else if (navValues.transfer)
      return (
        <button onClick={transferClick} className="mainpage transfer">
          Transfer
        </button>
      );
    //TODO Routes
    else
      return (
        <motion.div
          initial={{
            opacity: 0,
            x: 50,
            y: -250,
          }}
          animate={{
            opacity: 1,
            x: 0,
            y: 0,
          }}
          transition={{ duration: 1 }}
        >
          <MainEntryStyle>
            <p>To Be Added</p>
          </MainEntryStyle>
        </motion.div>
      );
  };

  return (
    <StyledMainPage>
      {permission === "USER_HR" && renderedUserHRView}
      {permission === "ADMIN_USER" && renderedAdminUserView()}
      {permission === "USER" && renderedUserView()}

      {/* {permission === "ERROR" && <p>NO AUTH</p>} */}
      <Modal width="90%" showModal={modal} onClose={(e) => setModal(false)}>
        {success ? (
          <Success />
        ) : (
          <TransferForm onClose={(e) => setModal(false)} />
        )}
      </Modal>
      {vLoad && <LoaderHolder />}
    </StyledMainPage>
  );
};

export default MainPage;
