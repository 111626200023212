import React, { useEffect } from "react";
//components
import ModalStyle from "./styles";

const Modal = ({ children, width, onClose, showModal }) => {
  const escFunction = (e) => {
    if (e.keyCode === 27) {
      onClose(e);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    // const data
  }, []);

  const handleClick = (e) => {
    if (e.target.id === "modalBackground") {
      return onClose(e);
    } else {
      return;
    }
  };

  //   const closeModal =
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("mousedown", handleClick, false);
  }, []);

  return (
    showModal && (
      <ModalStyle id="modalBackground" width={width}>
        <div className="modalMain">{children}</div>
      </ModalStyle>
    )
  );
};

export default Modal;
