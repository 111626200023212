import styled from "styled-components";

const ModalStyle = styled.div`
  border: solid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${(props) => props.theme.font};
  .modalMain {
    position: fixed;
    border-radius: 8px;
    background-color: #f0f2f5;
    width: ${(props) => props.width};
    padding: 15px 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: left 0.5s;
    left: 50%;
    height: 90vh;
    overflow: scroll;
  }
  .tableDiv {
    overflow: scroll;
    max-height: 450px;
    padding: 0 30px;
  }
  @media only screen and (max-width: 1200px) {
    .modalMain {
      transition: width 0.5s;
      width: 60vw;
    }
  }
  @media only screen and (max-width: 965px) {
    .modalMain {
      transition: width 0.5s;
      width: 70vw;
    }
  }
  @media only screen and (max-width: 828px) {
    .modalMain {
      transition: width 0.5s;
      width: 80vw;
    }
  }
  @media only screen and (max-width: 725px) {
    .modalMain {
      transition: width 0.5s;
      width: 90vw;
    }
  }
`;
export { ModalStyle };
