import styled from "styled-components";

const SingleVehicleStyle = styled.div`
  display: grid;
  grid-template-areas:
    ". img img . ids ids ids ids . ."
    ". img img . ids ids ids ids . ."
    ". detail detail detail detail detail detail detail detail ."
    ". detail detail detail detail detail detail detail detail ."
    " . map map map map map map map map ."
    " . map map map map map map map map ."
    " . map map map map map map map map ."
    " . map map map map map map map map .";

  .vehicleImage {
    grid-area: img;
    max-width: 400px;
    object-fit: contain;
    background: black;
    margin-bottom: 0.5em;
  }
  p {
    margin: 0.5em;
  }
  .vehicleInfo {
    grid-area: ids;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: end;
  }
  .vehicleDetails {
    grid-area: detail;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .first {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .vehicleMap {
    grid-area: map;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    background: white;
    margin: 0.5em;
    border-radius: 7px;
  }
  .imgCard {
    background: black;
  }
  div.vehicleImage > img {
  }
  #happyFace {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  .help {
    cursor: help;
  }

  .custom_Label {
    text-align: center;
    margin-left: 0.5em;
    border: none;
    font-weight: bold;
    padding: 0.5em;
    border-radius: 10px;
    color: white;
    background: #3e4b5a;
  }
  .custom_Info {
    padding: 0.5em;
  }
  span.bubble {
    /* border: none;
    font-weight: bold;
    padding: 0.5em;
    border-radius: 10px;
    color: white;
    background: #3e4b5a; */
  }

  /* media queries */
  @media only screen and (max-width: 1370) {
    display: grid;
    grid-template-areas:
      ". . . . img img . . . ."
      ". . . . img img . . . ."
      ". . . ids ids ids ids ids . . ."
      ". . . ids ids ids ids ids . . ."
      ". detail detail detail detail detail detail detail detail ."
      ". detail detail detail detail detail detail detail detail ."
      " . map map map map map map map map ."
      " . map map map map map map map map ."
      " . map map map map map map map map ."
      " . map map map map map map map map .";
  }
`;
export { SingleVehicleStyle };
