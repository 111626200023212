import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import ButtonCustom from '../../Component/ButtonCustom/ButtonCustom'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { StyledAccidents } from "./styles";
import { Context } from "../../App";
import {submitVehicleTransfer} from '../../api';
import axios from 'axios';



const Accidents = ({onClose}) => {
  // Variables
  const statusOptions = ["SUSPENDED", "APPROVED", "REJECTED"]
  const [driverName, setDriverName]= useState("");
  const [updatedStatus, setUpdatedStatus]= useState("");
  const [driverDetails, setDriverDetails] = useState({})
  const [isSearchOpen, setIsSearchOpen] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    mvr,
    markets,
    depts,
    setSuccess,
    setError,
    vehicles,
    drivers,
    userInfo,
    setVehicles,
    setVLoad,
  } = useContext(Context);

  const listOfNames = mvr.map(record => `${record.FIRST_NAME.toUpperCase()} ${record.LAST_NAME.toUpperCase()}`)

  // Handlers
  const handleChange = (e, val) => {
    e.preventDefault();
    setDriverName(val);
  }

  const handleStatusChange = (e, val) => {
    e.preventDefault();
    setUpdatedStatus(val);
    console.log(val);
  }

  const submitForm = async(e) => {
    e.preventDefault();
    console.log(userInfo);

    const driverVehicle = vehicles.find(vehicle => `${vehicle.DRIVER_FIRST_NAME.toUpperCase()} ${vehicle.DRIVER_LAST_NAME.toUpperCase()}` === driverName)
    
    // POST FOR VEHICLE (IF ASSIGNED)
    if (driverVehicle) {
      const transferData = {
        MARKET: driverVehicle.MARKET,
        DEPT: 'POOL',
        DRIVER_FIRST_NAME: 'UNASSIGNED',
        DRIVER_LAST_NAME: 'UNASSIGNED',
        DRIVER_PAY_ID: 'UNASSIGNED',
        VIN: driverVehicle.VIN,
        GEO_STATUS: driverVehicle.GEO_STATUS || "",
        PLATE: driverVehicle.PLATE,
        REASON: "Driving Privilage Revoked",
        EMAIL: userInfo.email,
        LAST_ACTIVE_DATE: driverVehicle.LAST_ACTIVE_DATE || "",
        FORMATTED_ADDRESS: driverVehicle.FORMATTED_ADDRESS || "",
      }


      submitVehicleTransfer(transferData,setSuccess, userInfo)
        .then(res => {
          setVehicles(res);
        })
        .catch(err => {
          setError(err);
        })
    }
    
    // POST FOR DRIVER
    console.log({
      ID: driverDetails.ID,
      MVR_RESULTS: updatedStatus
    })

    const newRecord = JSON.stringify({
      ID: driverDetails.ID,
      MVR_RESULTS: updatedStatus
    })

    let result = await axios.post(
      `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/mvr_admin_edit`,
      {
        ID: driverDetails.PAY_ID,
        MVR_RESULTS: updatedStatus
      }
    );

    console.log(result);

    closeDriverRecord();
  }

  const openDriverRecord = () => {
    // IMPORTANT NOTE: find only returns the first matching instance of the driver, but some duplicates do exist

    const driver = mvr.find(record => (`${record.FIRST_NAME.toUpperCase()} ${record.LAST_NAME.toUpperCase()}`) === driverName);
    setDriverDetails(driver);
    setIsSearchOpen(false);
  }

  const closeDriverRecord = () => {
    setDriverName("")
    setIsSearchOpen(true);
  }

  // JSX elements
  const driverRecord = ( 
    <section className="accidents-container">
      <h3>Name: {driverName}</h3>
      <p>ID: {driverDetails.ID}</p>
      <p>Market: {driverDetails.MARKET}</p>
      <p>Manager: {driverDetails.MANAGER}</p>
      <p>Current Status: {driverDetails.MVR_RESULTS}</p>
      <b>New Status:</b>
      <Autocomplete
        id="status-choice"
        options={statusOptions}
        onChange={handleStatusChange}
        getOptionLabel={(option) => `${option}`}
        renderInput={(params) => (
          <TextField {...params} label="Status" variant="outlined" />
        )}
      />
      <ButtonCustom onClick={submitForm} >Update Driver Status</ButtonCustom>  
      <ButtonCustom onClick={closeDriverRecord} >Back to Search</ButtonCustom>  
    </section>
  )


  const search = (
    <section className="accidents-container">
      <AppBar position='relative' className='accident-nav'>
        {/* <Toolbar className='nav-container'> */}
          <Autocomplete
            id="driver-search"
            options={listOfNames}
            onChange={handleChange}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => (
              <TextField {...params} label="Select a Driver" variant="outlined" />
            )}
          />
          <ButtonCustom onClick={openDriverRecord} >Edit Driver Records</ButtonCustom>  
        {/* </Toolbar> */}
      </AppBar>
    </section>
  )

  return (
    <StyledAccidents>
      {isSearchOpen ? search : driverRecord}
    </StyledAccidents>
  )
}

export default Accidents;
