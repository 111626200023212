import styled from "styled-components";

const TransferStyle = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  .MuiInputBase-root {
    margin: 0.5em;
  }
  .transfertitle {
    border: none;
    padding: 0.5em;
    border-radius: 2px;
    text-transform: uppercase;
    background: rgb(33, 40, 62);
    color: white;
    font-weight: bold;
    border-radius: 3px;
  }
  .transfersubmit {
    padding: 1em;
    font-size: 16px;
    outline: none;
    border: none;
    margin-top: 1em;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    background: #337ab7;
    font-weight: bold;
    color: white;
  }
  .transfersubmit:disabled {
    opacity: 0.3;
  }

  .online_geo {
    border: none;
    width: 75%;
    align-self: center;
    padding-bottom: 0.1em;
    padding-top: 0.1em;
    justify-self: center;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    border-radius: 7px;
    color: white;
    background: #25d366;
  }
  .offline_geo {
    border: none;
    align-self: center;
    justify-self: center;
    padding-bottom: 0.1em;
    padding-top: 0.1em;
    width: 75%;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    border-radius: 7px;
    color: white;
    background: #ff5a5f;
  }
  .old_vehicle {
    margin-top: 1em;
    padding: 1em;
    border: none;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    background: #e8e8e8;
  }
  .oldlabel {
    border: none;
    background: #687e96;
    border-radius: 20px;
    width: 100%;
    justify-self: end;
    padding: 0.2em 0 0.2em 0;
    margin-right: 0.2em;
    font-weight: bold;
    color: white;
  }
  .transfersubmit:hover {
    cursor: pointer;
  }
  .secondrow {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
  }
  .spacer {
    margin-left: 15px;
  }
`;

export default TransferStyle;
