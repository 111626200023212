import React, {useState, useEffect, useContext} from 'react';
import { Context } from "../../App";
import EditVehicleStyle from './styles';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonCustom from "../ButtonCustom/ButtonCustom";

import {
  TextField,
  makeStyles,
} from "@material-ui/core";
import DynamicTable from "../DynamicTable/DynamicTable";
import { motion } from "framer-motion";


const EditVehicle = () => {
  const {
    vehicles,
    setSuccess,
    setVehicles,
    setVLoad,
    vehicleResults,
    setVehicleResults,
    setValid,
    setSearchText,
    setSearchModal
  } = useContext(Context);

  const searchFieldOptions = ['VEHICLE_ID', 'PLATE', 'VIN'];
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState({});
  const [searchField, setSearchField] = useState("");
  const [isResultDisplayed, setIsResultDisplayed] = useState(false);
  const [isNoPlateTableDisplayed, setIsNoPlateTableDisplayed] = useState(false);
  const [text, setText] = useState("");
  const [APIFilter, setAPIFilter] = useState({
    VINS: "",
    DEPT: "",
    MARKET: "",
    DRIVER_FIRST_NAME: "",
    DRIVER_LAST_NAME: "",
  });


  // Get vehicle results (should be handled higher up, happens twice)

  useEffect(() => {
    getCleanedVehicleResults()
  }, [setValid, APIFilter, setSearchModal, setSearchText, setVLoad, setVehicleResults])

  // Initial Data Fetching
  const getCleanedVehicleResults = async () => {
    setVLoad(true);
    try {
      let vehicles = await axios.post(
        `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_vehicles`,
        APIFilter
      );

      //we want statuses as well
      const justVins = vehicles.data.data.map((v) => v.VIN);
      let geo_status = await axios.post(
        `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_locations_v2`,
        {
          VIN: justVins,
        }
      );

      let cleaned = [];
      vehicles.data.data.forEach((f) => {
        let found = geo_status.data.data.find((g) => g.VIN === f.VIN);
        if (found) {
          cleaned.push({ ...f, GEO_STATUS: found.IS_DEVICE_COMMUNICATING });
        } else {
          cleaned.push({ ...f, GEO_STATUS: "NO GEOTAB" });
        }
      });
      
      // Filter specific tot his file
      cleaned = cleaned.filter(vehicle => vehicle.PLATE === '')

      setValid(vehicles.data.message);
      setVehicleResults(cleaned);
      setVLoad(false);
      setSearchText(setText(APIFilter));
      setSearchModal(false);
    } catch (e) {
      setVLoad(false);
    }
  }

  // Handler Functions
  const handleFieldSelection = (e, values) => {
    const fieldOptions = vehicles.map(vehicle => {
      return vehicle[values]
    });

    setSearchField(values);
    setSearchOptions(fieldOptions);
  }

  const handleSearchInput = (e, values) => {
    setSearchTerm(values);
  }

  const editVehicle = e => {
    e.preventDefault();
    const result = vehicles.find(vehicle => vehicle[searchField] === searchTerm);
    setSearchResult(result, setIsResultDisplayed(true));
  }

  const submitEdit = async (e) => {
    e.preventDefault();
    
    const body = {
      vin: searchResult.VIN,
      updated_value: text,
      key: "PLATE"
    }
    try {
      let res = await axios.post(
        `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/edit_vehicle`
      , body)
      console.log(res)
      setIsResultDisplayed(false);
    } catch (err) {
      alert(err)
      setIsResultDisplayed(false);
    }
  }

  const handleTextInput = e => {
    e.preventDefault();
    setText(e.target.value);
    console.log(e.target.value)
  }

  const backToSearch = e => {
    setIsResultDisplayed(false);
    setIsNoPlateTableDisplayed(false);
  }

  const clickVehicleRow = e => {
    const result = vehicles.find((i) => i.VIN === e.currentTarget.id)
    console.log(result)
    setSearchResult(result)
    setIsResultDisplayed(true);
    setIsNoPlateTableDisplayed(false);
  }

  const formatVehicleResults = (data) => data.map((i) => {
    return (
      { 
        vehicle_id: i.VEHICLE_ID,
        plate: i.PLATE,
        driver: `${i.DRIVER_FIRST_NAME} ${i.DRIVER_LAST_NAME}`,
        VIN: i.VIN,
        dept: i.DEPT,
        office: i.MARKET,
        status:
          i.GEO_STATUS === "TRUE"
            ? "online"
            : i.GEO_STATUS === "FALSE"
              ? "offline"
              : "missing",
      }
    )
  })

  const search = (
    <form className="search" onSubmit={editVehicle}>
      <Autocomplete
        className="field-selection"
        id="field-selection"
        options={searchFieldOptions}
        onInputChange={handleFieldSelection}
        getOptionLabel={(option) => `${option}`}
        renderInput={(params) => (
          <TextField {...params} required label="Category" variant="outlined" />
        )}
      />
      <Autocomplete
        className="search-term"
        id="search-term"
        options={searchOptions}
        onInputChange={handleSearchInput}
        getOptionLabel={(option) => `${option}`}
        renderInput={(params) => (
          <TextField {...params} required label="Search Term" variant="outlined" />
        )}
      />
      <ButtonCustom type="submit" className="submitButton">
          Select Vehicle
      </ButtonCustom>
    </form>
  )

  const editVehicleForm = (
    <form className="search" onSubmit={submitEdit}>
      <p>MAKE: {searchResult.MAKE}</p>
      <p>MODEL: {searchResult.MODEL}</p>
      <p>VEHICLE ID: {searchResult.VEHICLE_ID}</p>
      <p>VIN: {searchResult.VIN}</p>
      <TextField
        required={true}
        className="input-field" 
        key="PLATE"
        label="PLATE"
        variant="outlined"
        onChange={e => handleTextInput(e)}
      />
      <ButtonCustom type="submit" className="submitButton">
          Edit Vehicle
      </ButtonCustom>
      <ButtonCustom className="backButton" onClick={backToSearch}>
          Back to Search
      </ButtonCustom>
    </form>
  )

  const noPlateTable = (
    <DynamicTable
      keyValue={"VIN"}
      headers={[
        "Vehicle ID",
        "License Plate",
        "Driver",
        "VIN",
        "Department",
        "Office",
        "Geotab Status",
      ]}
      clickRow={clickVehicleRow}
      rows={formatVehicleResults(vehicleResults)}
    />
  )

  const renderedComponent = () => {
    if (isNoPlateTableDisplayed) return noPlateTable
    if (isResultDisplayed) return editVehicleForm
    return search
  }

  return (
    <EditVehicleStyle>
      {renderedComponent()}
      <ButtonCustom onClick={() => setIsNoPlateTableDisplayed(!isNoPlateTableDisplayed)}>{isNoPlateTableDisplayed ? 'Search For a Vehicle': 'Filter By No Plate'}</ButtonCustom>
    </EditVehicleStyle>
  )
}

export default EditVehicle;
