import React, { useContext } from 'react'
import { Context } from '../../App'
import ButtonCustom from '../ButtonCustom/ButtonCustom'
import './Reports.css'
// Report Components
import TerminationsReport from './Terminations/TerminationsReport'

export default function Reports() {
  const { openReport, setOpenReport } = useContext(Context)

  const handleClick = (e) => {
    e.preventDefault()
    console.log(e.target.closest('article').id)
    setOpenReport(e.target.closest('article').id)
  }

  const renderOpenReport = () => {
    switch (openReport) {
      case 'terminations':
        return <TerminationsReport />
      default:
        return (
          <section className="reports-wrapper">
            <article id="terminations" className="reports-row">
              <ButtonCustom
                onClick={(e) => handleClick(e)}
                className="reports-button"
              >
                Terminations
              </ButtonCustom>
            </article>
          </section>
        )
    }
  }

  return (
    renderOpenReport()
  )
}
