import React, {useContext} from "react";
import MainEntryStyle from "./styles";
import { Context } from "../../App";
import Success from "../../Component/Success/Success";

import { motion } from "framer-motion";
import Search from "../Search/Search";
import AddVehicle from "../AddVehicle/AddVehicle";
import Accidents from "../../Container/Accidents/Accidents";
import EditVehicle from "../EditVehicle/EditVehicle";
import Reports from "../Reports/Reports";

const MainEntry = ({ ...props }) => {
  const { success } = useContext(Context);
  const renderComponent = () => {

    if (props.type === "inventory") return <Search />;

    if (props.type === "logs") return <p>Logs Component Here</p>

    if (props.type === "addVehicle") return (success ? <Success /> : <AddVehicle />)

    if (props.type === "editVehicle") return (success ? <Success /> : <EditVehicle />)

    if (props.type === "accident") return <Accidents />
    if (props.type === "reports") return <Reports />

    return <p>Missing</p>

  }

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 50,
        y: -250,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 1 }}
    >
      <MainEntryStyle>
        {renderComponent()}
      </MainEntryStyle>
    </motion.div>
  );
};

export default MainEntry;
