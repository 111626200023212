import React from "react";
import { ButtonStyle } from "./styles";
import { motion } from "framer-motion";
const ButtonCustom = ({ background = "", className, onClick, ...props }) => {
  return (
    <motion.div
      className={className}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <ButtonStyle onClick={onClick} background={background}>
        {props.children}
      </ButtonStyle>
    </motion.div>
  );
};

export default ButtonCustom;
