import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../App";
import AddVehicleStyle from "./styles";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ButtonCustom from "../ButtonCustom/ButtonCustom";

const AddVehicle = (props) => {
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const [newOptionValue, setNewOptionValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { setSuccess, setVehicles, setVLoad, filterValues } =
    useContext(Context);

  // Get Dropdown Fields/Options
  const getData = async () => {
    setVLoad(true);
    try {
      const res = await axios.get(
        `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_new_vehicle_dropdowns`
      );

      setOptions((prev) => {
        return { ...prev, ...res.data };
      });
      setOptions((prev) => {
        return { ...prev, department: filterValues.DEPT };
      });
      setVLoad(false);
    } catch (err) {
      alert(err);
      setVLoad(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Each vehicle has many fields, each field has many options

  // options and fields are fake, will need to be refactored for actual API response later (should this come from context?).

  const dropdownFields = [
    "department",
    "make",
    "models",
    "market",
    "color",
    "type",
  ];
  const addNewFields = ["make", "models", "color"];
  const nullableFields = ["plate", "geotab_id"];

  const [fields, setFields] = useState([
    "make",
    "models",
    "color",
    "type",
    "market",
    "department",
    "vehicle_id",
    "vin",
    "year",
    "plate",
    "geotab_id",
  ]);

  const [options, setOptions] = useState({
    vehicle_id: [],
    vin: [],
    plate: [],
    department: [],
    make: [],
    models: [],
    market: [],
    year: [],
    color: [],
    type: [],
    geotab_id: [],
  });

  const [selected, setSelected] = useState({
    vehicle_id: "",
    vin: "",
    plate: "",
    department: "",
    year: "",
    geotab_id: "",
    make: "",
    models: "",
    market: "",
    color: "",
    type: "",
  });

  const handleNewSelection = (e, values, reason) => {
    const { id } = e.target;
    const parsedIDs = id.split("-");
    const parsedID = parsedIDs[0];

    if (values && parsedID) {
      setSelected((prev) => ({ ...prev, [parsedID]: values }));
    } else if (reason === "clear") {
      const clearButtonId = e.target.closest("section").id;

      setSelected((prev) => ({ ...prev, [clearButtonId]: "" }));
    }
  };

  const handleTextInput = (e, field) => {
    const { value } = e.target;

    setSelected((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const openInput = (e, field) => {
    e.preventDefault();

    setIsInputOpen(true);
    setCurrentField(field);
  };

  const closeInput = (e) => {
    e.preventDefault();
    setIsInputOpen(false);
  };

  // POST will live in here to add option to the correct field
  const addOption = (e) => {
    e.preventDefault();

    setOptions((prev) => {
      return {
        ...prev,
        [currentField]: [...prev[currentField], newOptionValue],
      };
    });
    setIsInputOpen(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const submission = selected;

    if (!submission.geotab_id) submission.geotab_id = null;
    if (!submission.plate) submission.plate = null;
    submission.year = parseInt(submission.year);

    let result = await axios.post(
      `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/add_new_vehicle`,
      submission
    );

    setSuccess(true);

    setVehicles((prev) => {
      return [...prev, result.data];
    });

    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  const dropdowns = fields.map((field, index) => {
    if (dropdownFields.includes(field) && addNewFields.includes(field)) {
      return (
        <section className="dropdown" key={field} id={field}>
          <Autocomplete
            className="input-field"
            id={field}
            options={options[field]}
            onInputChange={handleNewSelection}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={field}
                variant="outlined"
              />
            )}
          />
          <ButtonCustom
            id={`add-${field}`}
            className="submitButton"
            onClick={(e) => openInput(e, field)}
            disabled={true}
          >
            New {field}
          </ButtonCustom>
        </section>
      );
    } else if (
      dropdownFields.includes(field) &&
      !addNewFields.includes(field)
    ) {
      return (
        <section className="dropdown" key={field} id={field}>
          <Autocomplete
            className="input-field"
            id={field}
            options={options[field]}
            onInputChange={handleNewSelection}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={field}
                variant="outlined"
              />
            )}
          />
        </section>
      );
    }
  });

  const inputFields = fields.map((field, index) => {
    if (!dropdownFields.includes(field)) {
      return (
        <section className="text-input" key={field}>
          <TextField
            required={!nullableFields.includes(field)}
            className="input-field"
            key={field}
            label={field}
            variant="outlined"
            value={selected[field] || ""}
            onChange={(e) => handleTextInput(e, field)}
          />
        </section>
      );
    }
  });

  const addVehicleForm = (
    <form className="vehicle-form" onSubmit={submitForm}>
      <p className="header">Add a Vehicle</p>
      <hr className="breaker" />
      <section className="input-grid">
        <article className="dropdowns">{dropdowns}</article>
        <article className="input-fields">{inputFields}</article>
      </section>
      <ButtonCustom type="submit" className="submitButton">
        Submit
      </ButtonCustom>
    </form>
  );

  const addOptionForm = (
    <section id={currentField} className={currentField}>
      <p>Add An Option for {currentField}</p>
      <hr className="breaker" />
      <input
        onChange={(e) => setNewOptionValue(e.target.value)}
        value={newOptionValue}
      />
      <ButtonCustom
        data={currentField}
        id={currentField}
        className="submitButton"
        onClick={(e) => addOption(e)}
      >
        Add to {currentField}
      </ButtonCustom>
      <ButtonCustom className="submitButton" onClick={closeInput}>
        Go Back
      </ButtonCustom>
    </section>
  );

  return (
    <AddVehicleStyle>
      <section>{isInputOpen ? addOptionForm : addVehicleForm}</section>
    </AddVehicleStyle>
  );
};

export default AddVehicle;
