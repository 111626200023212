import React from "react";
import LoaderStyle from "./styles";
import { motion, AnimatePresence } from "framer-motion";

const Loader = ({ ...props }) => {
  // return (
  //   <LoaderStyle>
  //     <div className="holder">
  //       <AnimatePresence>
  //         {props.loading && (
  //           <motion.div
  //             className="loader"
  //             initial={{ opacity: 0 }}
  //             animate={{ opacity: 1 }}
  //             exit={{ opacity: 0 }}
  //           >
  //             {/* <div className="inner one"></div>
  //             <div className="inner two"></div>
  //             <div className="inner three"></div> */}
  //             <p>Hi</p>
  //           </motion.div>
  //         )}
  //         {/* <p>Loading...</p> */}
  //       </AnimatePresence>
  //     </div>
  //   </LoaderStyle>
  // );
  // return (
  //   <motion.div
  //     initial={{ opacity: 0 }}
  //     animate={{ opacity: 1 }}
  //     exit={{ opacity: 0 }}
  //     transition={{ duration: 0.5 }}
  //   >
  //     <p>Hi</p>
  //   </motion.div>
  // );
  return (
    <LoaderStyle>
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube"></div>
        <div className="sk-cube2 sk-cube"></div>
        <div className="sk-cube4 sk-cube"></div>
        <div className="sk-cube3 sk-cube"></div>
      </div>
    </LoaderStyle>
  );
};
export default Loader;
