import React, { useContext } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import VehicleResultStyle from "./styles";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { Context } from "../../App";
import { motion } from "framer-motion";
import axios from "axios";
import DynamicTable from "../DynamicTable/DynamicTable";
import Loader from "../Loader/Loader";

const VehicleResult = ({ ...props }) => {
  const {
    vehicleResults,
    setValid,
    setMapModal,
    setVehicleLocations,
    setSingleVehicle,
    searchText,
    setVLoad,
    setAllMap,
  } = useContext(Context);

  const getAllLocations = async (e) => {
    setVLoad(true);
    try {
      let justVins = vehicleResults.map((i) => i.VIN);
      let locations = await axios.post(
        `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_locations_v2`,
        {
          VIN: justVins,
        }
      );
      //console.log(locations.data.data);
      setVLoad(false);
      setAllMap(locations.data.data);
    } catch (e) {
      setVLoad(false);
    }
  };

  const clickVehicleRow = async (e) => {
    //just tmp

    setVLoad(true);
    setSingleVehicle([vehicleResults.find((i) => i.VIN === e.currentTarget.id)]);
    try {
      let locations = await axios.post(
        `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_locations_v2`,
        {
          VIN: [e.currentTarget.id],
        }
      );

      setVehicleLocations(locations.data.data);

      setVLoad(false);
      //we want load to finish then modal to appear

      setMapModal(true);
    } catch (e) {
      setVLoad(false);
      setMapModal(false);
    }
  };

  const formatVehicleResults = (data) => data.map((i) => ({
    vehicle_id: i.VEHICLE_ID,
    plate: i.PLATE,
    driver: `${i.DRIVER_FIRST_NAME} ${i.DRIVER_LAST_NAME}`,
    VIN: i.VIN,
    dept: i.DEPT,
    office: i.MARKET,
    status:
      i.GEO_STATUS === "TRUE"
        ? "online"
        : i.GEO_STATUS === "FALSE"
          ? "offline"
          : "missing",
  }))

  const getFormattedDateTime = () => new Date()
    .toISOString()
    .slice(0, 19)
    .replace(/-/g, "/")
    .replace("T", " ")
    .split(' ')
  [0]

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: -50,
        y: -250,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 1 }}
    >
      <VehicleResultStyle>
        <div className="vehicle_holder">
          <p className="terms">{searchText}</p>
          <ButtonCustom onClick={getAllLocations}>View On Map</ButtonCustom>
          <CSVLink
            data={formatVehicleResults(vehicleResults)}
            filename={`Inventory-${getFormattedDateTime()}.csv`}
          >
            <ButtonCustom>Export to Excel</ButtonCustom>
          </CSVLink>
        </div>

        <DynamicTable
          keyValue={"VIN"}
          headers={[
            "Vehicle ID",
            "License Plate",
            "Driver",
            "VIN",
            "Department",
            "Office",
            "Geotab Status",
          ]}
          clickRow={clickVehicleRow}
          rows={formatVehicleResults(vehicleResults)}
        />
      </VehicleResultStyle>
    </motion.div>
  );
};

export default VehicleResult;
