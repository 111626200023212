import React, { useState, useContext } from "react";
import { Context } from "../../App";
import TransferStyle from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactTooltip from "react-tooltip";
import { validateTransferData, submitVehicleTransfer } from "../../api";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const TransferForm = ({ onClose }) => {
  const mvr = [
    {
      DRIVER_FIRST_NAME: "MICHAEL",
      DRIVER_LAST_NAME: "BHARRAT",
      DRIVER_PAY_ID: "1234",
      MANAGER: "Paul Crowdis",
    },
  ];

  const {
    markets,
    setSuccess,
    setError,
    vehicles,
    drivers,
    userInfo,
    setVehicles,
    setVLoad,
    filterValues,
  } = useContext(Context);
  const classes = useStyles();
  const [transferData, setTransferData] = useState({
    MARKET: "",
    DEPT: "",
    DRIVER_FIRST_NAME: "",
    DRIVER_LAST_NAME: "",
    DRIVER_PAY_ID: "",
    VIN: "",
    GEO_STATUS: "",
    PLATE: "",
    REASON: "",
    EMAIL: "",
    LAST_ACTIVE_DATE: "",
    FORMATTED_ADDRESS: "",
  });

  const [reasons, setReasons] = useState([
    "New Hire",
    "Termination",
    "Dept Change",
    "Driver Change",
    "Location Change",
    "R&M",
    "Vehicle Returned from R&M",
    "Accident",
    "Driving Privilage Revoked",
    "Driving Privilage Reinstated",
  ]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [focusedVehicle, setFocusedVehicle] = useState({});
  const handleChange = (e) => {
    let update = transferData;
    update[e.currentTarget.id] = e.target.value.toUpperCase().trim();

    setTransferData({ ...update });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const validation = await validateTransferData(transferData);

    if (validation === true) {
      setIsDisabled(true);

      submitVehicleTransfer(transferData, setSuccess, userInfo)
        .then((res) => {
          setIsDisabled(false);
          setVehicles(res);
          onClose();
        })
        .catch((err) => {
          setError(err);
          setIsDisabled(false);
        });

      // setTimeout(() => {
      //   setSuccess(false);
      //   setIsDisabled(false);
      //   setVehicles(v.data.vehicle);
      //   onClose();
      // }, 2000);
    } else {
      setError(validation);
    }
  };

  const nameChange = (e, n) => {
    if (n) {
      if (transferData.DEPT === "POOL") {
        setTransferData({
          ...transferData,
          DRIVER_FIRST_NAME: "UNASSIGNED",
          DRIVER_LAST_NAME: "UNASSIGNED",
          DRIVER_PAY_ID: "UNASSIGNED",
        });
      } else {
        setTransferData({
          ...transferData,
          DRIVER_FIRST_NAME: n.DRIVER_FIRST_NAME,
          DRIVER_LAST_NAME: n.DRIVER_LAST_NAME,
          DRIVER_PAY_ID: n.DRIVER_PAY_ID,
        });
      }
    } else {
      //console.log("deleted");
      //console.log();
      if (transferData.DEPT === "POOL") {
        //console.log("NO MORE");
        setTransferData({
          ...transferData,
          DRIVER_FIRST_NAME: "UNASSIGNED",
          DRIVER_LAST_NAME: "UNASSIGNED",
          DRIVER_PAY_ID: "UNASSIGNED",
          DEPT: "POOL",
        });
      } else {
        setTransferData({
          ...transferData,
          DRIVER_FIRST_NAME: "",
          DRIVER_LAST_NAME: "",
          DRIVER_PAY_ID: "",
        });
      }
    }
  };

  const plateOrVinSelect = (e, n) => {
    if (n) {
      //console.log(n);
      // setTransferData({ ...transferData, PLATE: n.PLATE });
      setFocusedVehicle(n);
      setVLoad(true);
      //axios call for location
      axios
        .post(
          `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_location`,
          {
            VIN: n.VIN,
          }
        )
        .then((o) => {
          //console.log(o.data);
          if (o.data.IS_DEVICE_COMMUNICATING !== "FALSE") {
            setFocusedVehicle({ ...n, IS_DEVICE_COMMUNICATING: "Online" });
            setTransferData({
              ...transferData,
              VIN: n.VIN,
              PLATE: n.PLATE,
              GEO_STATUS: "online",
              FORMATTED_ADDRESS: o.data.FORMATTED_ADDRESS,
              LAST_ACTIVE_DATE: o.data.LAST_ACTIVE_DATE,
            });
          } else {
            setFocusedVehicle({
              ...n,
              IS_DEVICE_COMMUNICATING: "Offline",
              LAST_ACTIVE_DATE: o.data.LAST_ACTIVE_DATE,
            });
            setTransferData({
              ...transferData,
              VIN: n.VIN,
              PLATE: n.PLATE,
              GEO_STATUS: "offline",
              FORMATTED_ADDRESS: o.data.FORMATTED_ADDRESS,
              LAST_ACTIVE_DATE: o.data.LAST_ACTIVE_DATE,
            });
          }
          setVLoad(false);
        })
        .catch((p) => {
          //console.log(p.response);
          if (p.response.status === 404) {
            setFocusedVehicle({
              ...n,
              IS_DEVICE_COMMUNICATING: "No Geotab",
              LAST_ACTIVE_DATE: "No Geotab",
            });
            setTransferData({
              ...transferData,
              VIN: n.VIN,
              PLATE: n.PLATE,
              GEO_STATUS: "no geotab",
              FORMATTED_ADDRESS: "no geotab",
              LAST_ACTIVE_DATE: "no geotab",
            });
            setVLoad(false);
          } else {
            setFocusedVehicle({
              ...n,
              IS_DEVICE_COMMUNICATING: "Offline",
              LAST_ACTIVE_DATE: "",
            });
            setTransferData({
              ...transferData,
              VIN: n.VIN,
              PLATE: n.PLATE,
              GEO_STATUS: "no geotab",
              FORMATTED_ADDRESS: "no geotab",
              LAST_ACTIVE_DATE: "no geotab",
            });
            setVLoad(false);
          }
        });
    } else {
      //console.log("vehicle deleted");

      setTransferData({ ...transferData, PLATE: "" });
      setFocusedVehicle({});
    }
  };
  const marketSelect = (e, n) => {
    if (n) {
      //console.log(n);
      setTransferData({ ...transferData, MARKET: n });
    } else {
      setTransferData({ ...transferData, MARKET: "" });
    }
  };
  const reasonChange = (e, n) => {
    if (n) {
      //console.log(n);
      setTransferData({ ...transferData, REASON: n });
    } else {
      setTransferData({ ...transferData, REASON: "" });
    }
  };
  const departmentSelect = (e, n) => {
    if (n) {
      //console.log(n);
      if (n === "POOL") {
        setTransferData({
          ...transferData,
          DEPT: n,
          DRIVER_FIRST_NAME: "UNASSIGNED",
          DRIVER_LAST_NAME: "UNASSIGNED",
          DRIVER_PAY_ID: "UNASSIGNED",
        });

        // console.log("change driver too");
      } else {
        setTransferData({ ...transferData, DEPT: n });
      }
    } else {
      setTransferData({ ...transferData, DEPT: "" });
    }
  };

  return (
    <TransferStyle onSubmit={onSubmit}>
      <h2 className="transfertitle">Vehicle Transfer Form</h2>
      <>
        <div className="names">
          {transferData.DEPT === "POOL" ? (
            <p>Name: UNASSIGNED UNASSIGNED</p>
          ) : (
            <Autocomplete
              id="combo-box-demo"
              options={drivers}
              onChange={nameChange}
              getOptionLabel={(option) =>
                `${option.DRIVER_FIRST_NAME} ${option.DRIVER_LAST_NAME}`
              }
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Driver Name"
                  variant="outlined"
                  // value={`${transferData.DRIVER_FIRST_NAME} ${transferData.DRIVER_LAST_NAME}`}
                />
              )}
            />
          )}
        </div>
        <div className="secondrow">
          <Autocomplete
            id="combo-box-demo"
            options={vehicles}
            onChange={plateOrVinSelect}
            value={{ PLATE: transferData.PLATE }}
            getOptionLabel={(option) => option.PLATE}
            style={{ width: 250 }}
            renderInput={(params) => (
              <TextField {...params} label="License Plate" variant="outlined" />
            )}
          />
          <p className="spacer">or</p>
          <Autocomplete
            id="combo-box-demo"
            options={vehicles}
            onChange={plateOrVinSelect}
            value={{ VIN: transferData.VIN }}
            getOptionLabel={(option) => option.VIN}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="VIN" variant="outlined" />
            )}
          />
        </div>

        <div className="dropdowns">
          <FormControl variant="outlined" className={classes.formControl}>
            <Autocomplete
              id="combo-box-demo"
              options={markets}
              onChange={marketSelect}
              value={transferData.MARKET}
              getOptionLabel={(option) => option}
              style={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Market" variant="outlined" />
              )}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <Autocomplete
              id="combo-box-demo"
              options={filterValues.DEPT}
              onChange={departmentSelect}
              value={transferData.DEPT}
              getOptionLabel={(option) => option}
              style={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Department" variant="outlined" />
              )}
            />
          </FormControl>
        </div>

        <Autocomplete
          id="combo-box-demo"
          options={reasons}
          onChange={reasonChange}
          value={transferData.REASON}
          getOptionLabel={(option) => option}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Reason" variant="outlined" />
          )}
        />
        {/* <TextField
        multiline={true}
        minrow="10"
        rowsMax="10"
        id="REASON"
        label="Reason"
        variant="outlined"
        onChange={handleChange}
        required
      /> */}
        {!isDisabled ? (
          <button className="transfersubmit" type="submit">
            SUBMIT
          </button>
        ) : (
          <button disabled className="transfersubmit" type="submit">
            SUBMIT
          </button>
        )}

        {Object.keys(focusedVehicle).length > 0 && (
          <div className="old_vehicle">
            <label className="oldlabel">VIN:</label>
            <p>{focusedVehicle.VIN}</p>
            <label className="oldlabel">VEHICLE ID:</label>
            <p>{focusedVehicle.VEHICLE_ID}</p>
            <label className="oldlabel">GEOTAB STATUS:</label>

            {focusedVehicle.IS_DEVICE_COMMUNICATING ? (
              focusedVehicle.IS_DEVICE_COMMUNICATING === "Online" ? (
                <p className="online_geo">
                  {focusedVehicle.IS_DEVICE_COMMUNICATING}
                </p>
              ) : (
                <>
                  <a data-tip data-for="happyFace" className="offline_geo">
                    {focusedVehicle.IS_DEVICE_COMMUNICATING}
                  </a>
                  <ReactTooltip id="happyFace" type="dark">
                    <span>{focusedVehicle.LAST_ACTIVE_DATE}</span>
                  </ReactTooltip>
                </>
              )
            ) : (
              <p>Loading...</p>
            )}
            <label className="oldlabel">MAKE/MODEL:</label>
            <p>{`${focusedVehicle.YEAR} ${focusedVehicle.MAKE} ${focusedVehicle.MODEL}`}</p>

            <label className="oldlabel">EXT COLOR:</label>
            <p>{focusedVehicle.EXT_COLOR ? focusedVehicle.EXT_COLOR : "NA"}</p>
            <label className="oldlabel">PREVIOUS DRIVER:</label>
            <p>{`${focusedVehicle.DRIVER_FIRST_NAME} ${focusedVehicle.DRIVER_LAST_NAME}`}</p>
            <label className="oldlabel">PREVIOUS DEPT:</label>
            <p>{focusedVehicle.DEPT}</p>
            <label className="oldlabel">PREVIOUS MARKET:</label>
            <p>{focusedVehicle.MARKET}</p>
          </div>
        )}
      </>
    </TransferStyle>
  );
};
export default TransferForm;
