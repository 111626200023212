import React, { useState, useContext, useEffect } from "react";
import { StyledHRPage } from "./styles";
import Success from "../../Component/Success/Success";
import { Context } from "../../App";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Modal from "../../Component/Modal/Modal";
import TransferForm from "../../Container/TransferForm/TransferForm";
import TableComponent from "../../Component/TableComponent/TableComponent";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";

// Excel
import { CSVLink } from "react-csv";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    width: "75%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HRPage = ({ ...props }) => {
  const [didSubmitNew, setDidSubmitNew] = useState(false);
  const {
    setChipData,
    chipData,
    searched,
    setSearched,
    setLocalMVR,
    mvr,
    setMVR,
    setValid,
    setError,
    localMVR,
    markets,
    filterValues,
    managerEmail,
    permission,
  } = useContext(Context);

  //first load
  useEffect(() => {
    if (localMVR.length === 0 && didSubmitNew === false) {
      console.log({ mvr });
      setLocalMVR(mvr);
    }
  }, [mvr]);

  useEffect(() => {
    if (didSubmitNew) {
      let url = `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_mvr`;
      // url = `http://localhost:8000/get_mvr`;
      axios.get(url).then((x) => {
        setMVR(x.data);
        setLocalMVR(x.data);
        setDidSubmitNew(false);
      });
    }
  }, [didSubmitNew]);

  const classes = useStyles();
  const [addModal, setAddModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [resultData, setResultData] = useState({});
  const [revise, setRevise] = useState({
    FIRST_NAME: "",
    LAST_NAME: "",
    MVR_RESULTS: "APPROVED",
    LICENSE_NUMBER: "",
    LICENSE_STATE: "",
    PAY_ID: "",
    notes: "",
  });

  const [add, setAdd] = useState({
    FIRST_NAME: "",
    LAST_NAME: "",
    DEPT: "",
    MARKET: "",
    LICENSE_NUMBER: "",
    LICENSE_STATE: "",
    MANAGER: "",
    // New stuff
    MVR_RESULTS: "APPROVED",
    PAY_ID: "",
    notes: "",
  });
  const [modal, setModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [key, setKey] = useState("LAST_NAME");

  // rows={localCollections}
  const clickRow = (e) => {
    let id = JSON.parse(e.currentTarget.id);

    let res = mvr.filter(
      (i) => id.FIRST_NAME === i.FIRST_NAME && id.LAST_NAME === i.LAST_NAME
    );
    //console.log(res);
    setResultData(res[0]);
    setResultModal(true);
    setRevise({
      FIRST_NAME: res[0].FIRST_NAME,
      LAST_NAME: res[0].LAST_NAME,
      PAY_ID: res[0].PAY_ID,
      MVR_RESULTS: "APPROVED",
      LICENSE_NUMBER: res[0].LICENSE_NUMBER,
      LICENSE_STATE: res[0].LICENSE_STATE,
      notes: "",
    });
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (permission !== "USER_HR") {
      props.history.push("/");
    }
  }, [permission]);

  const firstNameChange = (e) => {
    setAdd({ ...add, FIRST_NAME: e.currentTarget.value });
  };

  const lastNameChange = (e) => {
    setAdd({ ...add, LAST_NAME: e.currentTarget.value });
  };
  const notesChange = (e) => {
    setAdd({ ...add, notes: e.currentTarget.value });
    setRevise({ ...revise, notes: e.currentTarget.value });
  };

  // const managerChange = (e) => {
  //   setAdd({ ...add, MANAGER: e.currentTarget.value });
  // };
  const deptChange = (e, n) => {
    if (n) {
      setAdd({ ...add, DEPT: n });
    } else {
      setAdd({ ...add, DEPT: "" });
    }
  };
  const managerChange = (e, n) => {
    if (n) {
      setAdd({ ...add, MANAGER: n });
    } else {
      setAdd({ ...add, MANAGER: "" });
    }
  };
  const marketChange = (e, n) => {
    if (n) {
      setAdd({ ...add, MARKET: n });
    } else {
      setAdd({ ...add, MARKET: "" });
    }
  };

  const search_clicked = (e) => {
    //console.log("searching");
    //console.log(mvr);
    //console.log(chipData);
    let tmp_local = mvr;
    for (const c of chipData) {
      tmp_local = tmp_local.filter((i) =>
        i[c.key]
          .toLowerCase()
          .replace(/[^\w\s]/gi, "")
          .includes(c.label.toLowerCase().replace(/[^\w\s]/gi, ""))
      );
      //console.log(tmp_local);
    }
    setValid(`Found ${tmp_local.length} matches!`);
    setLocalMVR(tmp_local);
    setSearched(true);
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const setClickKey = (e) => {
    e.preventDefault();
    setKey(e.currentTarget.value);
  };
  const addChip = (e) => {
    e.preventDefault();

    if (searchVal !== "" || key === "Contract_Month") {
      //filter
      let tmp_chip = chipData.filter((i) => i.key !== key);

      tmp_chip.push({ key: key, label: searchVal });
      //console.log(tmp_chip);
      setChipData(tmp_chip);
      setSearchVal("");
    }
  };
  const clearFilter = (e) => {
    e.preventDefault();
    setLocalMVR(mvr);
    setChipData([]);
    setSearched(false);
  };

  const closeAdd = (e) => {
    setAddModal(false);
    setResultModal(false);
  };
  const addOpen = (e) => {
    setAddModal(true);
  };
  const licenseStateChange = (e, n) => {
    if (n) {
      setRevise({ ...revise, LICENSE_STATE: n });
    } else {
      setAdd({ ...revise, LICENSE_STATE: "" });
    }
  };

  const onNewDriver = (e) => {
    e.preventDefault();
    //checks
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      add.FIRST_NAME === "" ||
      add.LAST_NAME === "" ||
      add.DEPT === "" ||
      add.MARKET === "" ||
      add.MANAGER === "" ||
      add.MVR_RESULTS === "" ||
      add.PAY_ID === ""
    ) {
      setError("Make sure all fields are filled!");
    }
    //  else if (moment(selectedDate).format("MM/DD/YYYY") === "Invalid date") {
    //   setError("Please enter a valid birthdate");
    //
    else if (!re.test(add.MANAGER)) {
      setError("Please enter a valid email address!");
    } else {
      const newAdd = {
        ...add,
        BIRTH_DATE: moment(selectedDate).format("MM/DD/YYYY"),
      };

      // delete newAdd.MVR_RESULTS
      // delete newAdd.PAY_ID
      // delete newAdd.notes

      const {
        MVR_RESULTS,
        PAY_ID,
        notes,
        FIRST_NAME,
        LAST_NAME,
        LICENSE_NUMBER,
        LICENSE_STATE,
      } = add;
      const newRevise = {
        MVR_RESULTS,
        PAY_ID,
        notes,
        FIRST_NAME,
        LAST_NAME,
        LICENSE_NUMBER,
        LICENSE_STATE,
      };

      let url =
        "https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/initial_mvr";
      // url = "http://localhost:8000/initial_mvr"

      axios
        .post(
          url,
          {
            MVR_REQ: {
              ...newAdd,
            },
          },
          {
            crossOrigin: true,
          }
        )
        .then((v) => {
          console.log({ v });
          setDidSubmitNew(true);
          setValid("Uploaded");
          setAddModal(false);
          setAdd({
            FIRST_NAME: "",
            LAST_NAME: "",
            DEPT: "",
            MARKET: "",
            MANAGER: "",
          });
        });
    }
  };

  const resultsToggle = (e) => {
    e.preventDefault();
    console.log({ ...revise, MVR_RESULTS: e.target.value });
    setAdd({ ...add, MVR_RESULTS: e.target.value });
    setRevise({ ...revise, MVR_RESULTS: e.target.value });
  };
  const payIDChange = (e) => {
    e.preventDefault();
    setAdd({ ...add, PAY_ID: e.target.value });
    setRevise({ ...revise, PAY_ID: e.target.value });
  };
  const licenseNumberChange = (e) => {
    e.preventDefault();
    setRevise({ ...revise, LICENSE_NUMBER: e.target.value });
  };

  return (
    <StyledHRPage>
      <div className="card">
        <button onClick={addOpen} className="add">
          ADD
        </button>
        <div className="search_comp">
          {localMVR.length === mvr.length ? (
            <>
              <select className="filter_select" onChange={setClickKey}>
                <option value="LAST_NAME">Last Name</option>
                <option value="FIRST_NAME">First Name</option>
                <option value="DEPT">Department</option>
                <option value="MARKET">Location</option>
                <option value="MANAGER">Manager</option>
                <option value="MVR_RESULTS">MVR Results</option>
                {/* <option value="MAKE">Make</option> */}
                {/* <option value="MODEL">Model</option> */}
              </select>

              <input
                className="search"
                placeholder="search"
                value={searchVal}
                onChange={(e) => setSearchVal(e.currentTarget.value)}
              />

              <button className="add_filter" onClick={addChip}>
                + add filter
              </button>
            </>
          ) : (
            <button className="clear_filter" onClick={clearFilter}>
              Clear Filter
            </button>
          )}
        </div>
        <div className="chip_box">
          {chipData.length !== 0 && (
            <Paper component="ul" className={classes.root}>
              {chipData.map((data) => {
                let icon;

                return !searched ? (
                  <li key={data.key}>
                    <Chip
                      icon={icon}
                      label={data.label}
                      onDelete={
                        data.label === "React" ? undefined : handleDelete(data)
                      }
                      className={classes.chip}
                    />
                  </li>
                ) : (
                  <li key={data.key}>
                    <Chip
                      icon={icon}
                      label={data.label}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </Paper>
          )}
        </div>

        {chipData.length !== 0 && !searched && (
          <button className="search_button" onClick={search_clicked}>
            Search
          </button>
        )}

        <TableComponent
          headers={[
            {
              title: "Last Name",
              type: "LAST_NAME",
            },
            {
              title: "First Name",
              type: "FIRST_NAME",
            },
            {
              title: "Department",
              type: "DEPARTMENT",
            },
            {
              title: "Location",
              type: "LOCATION",
            },
            {
              title: "Manager",
              type: "MANAGER",
            },

            {
              title: "Date Results",
              type: "DATE_SUBMITTED",
            },
            {
              title: "MVR Results",
              type: "MVR_RESULTS",
            },
            {
              title: "Paylocity ID",
              type: "PAYLOCITY_ID",
            },
            // {
            //   title: "Make",
            //   type: "MAKE"
            // },
            // {
            //   title: "Model",
            //   type: "MODEL"
            // },
            // {}
            //   "Last Name",
            //   "First Name",
            //   "Department",
            //   "Location",
            //   "Manager",
            //   "Date Submitted",
            //   "MVR Results",
            //   "Date Results",
            //   "Paylocity ID",
            //   "Make",
            //   "Model"
          ]}
          rows={localMVR}
          clickRow={clickRow}
        />
      </div>
      <Modal width="90%" onClose={closeAdd} showModal={addModal}>
        <div className="new_mvr">
          <h3 className="title">NEW MVR</h3>
          <p className="driver">Driver Specific Info</p>
          <div className="name_container">
            <TextField
              label="First Name"
              variant="outlined"
              onChange={firstNameChange}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              onChange={lastNameChange}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />

            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Birthdate"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider> */}
          </div>
          <p className="driver">Company Specific Info</p>
          <div className="auto_contain">
            <Autocomplete
              id="combo-box-demo"
              options={filterValues.DEPT}
              onChange={deptChange}
              getOptionLabel={(option) => option}
              style={{ width: 235 }}
              renderInput={(params) => (
                <TextField {...params} label="Department" variant="outlined" />
              )}
            />
            <Autocomplete
              id="combo-box-demo"
              options={markets}
              onChange={marketChange}
              getOptionLabel={(option) => option}
              style={{ width: 235 }}
              renderInput={(params) => (
                <TextField {...params} label="Location" variant="outlined" />
              )}
            />
            <Autocomplete
              id="combo-box-demo"
              options={managerEmail}
              onChange={managerChange}
              getOptionLabel={(option) => option}
              style={{ width: 235 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Manager Email"
                  variant="outlined"
                />
              )}
            />
            {/* <TextField
              label="Manager Email"
              variant="outlined"
              onChange={managerChange}
              className="manager"
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            /> */}
          </div>
          <p className="driver">Company Specific Info</p>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Status"
              onChange={resultsToggle}
              value={add.MVR_RESULTS}
            >
              <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
              <MenuItem value={"DENIED"}>DENIED</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Paylocity ID"
            variant="outlined"
            value={add.PAY_ID}
            onChange={payIDChange}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
          />
          <textarea
            maxlength="1000"
            placeholder="Notes"
            rows="4"
            cols="50"
            onChange={notesChange}
          ></textarea>

          <div>
            <button className="submit" onClick={onNewDriver}>
              SUBMIT
            </button>
            <button onClick={closeAdd} className="cancel">
              CANCEL
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        width={"100%"}
        onClose={(e) => setResultModal(false)}
        showModal={resultModal}
      >
        <div className="outter">
          <div className="finalStep">
            <h3 className="name">{`${resultData.FIRST_NAME} ${resultData.LAST_NAME}`}</h3>
            {/* <p>{resultData.MVR_RESULTS}</p> */}
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Status"
                onChange={resultsToggle}
                value={revise.MVR_RESULTS}
              >
                <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                <MenuItem value={"DENIED"}>DENIED</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Paylocity ID"
              variant="outlined"
              value={revise.PAY_ID}
              onChange={payIDChange}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            {/* <div className="license_contain">
              <TextField
                label="License #"
                variant="outlined"
                value={revise.LICENSE_NUMBER}
                onChange={licenseNumberChange}
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                }}
              />
              <Autocomplete
                id="combo-box-demo"
                options={[
                  "AL",
                  "AK",
                  "AS",
                  "AZ",
                  "AR",
                  "CA",
                  "CO",
                  "CT",
                  "DE",
                  "DC",
                  "FM",
                  "FL",
                  "GA",
                  "GU",
                  "HI",
                  "ID",
                  "IL",
                  "IN",
                  "IA",
                  "KS",
                  "KY",
                  "LA",
                  "ME",
                  "MH",
                  "MD",
                  "MA",
                  "MI",
                  "MN",
                  "MS",
                  "MO",
                  "MT",
                  "NE",
                  "NV",
                  "NH",
                  "NJ",
                  "NM",
                  "NY",
                  "NC",
                  "ND",
                  "MP",
                  "OH",
                  "OK",
                  "OR",
                  "PW",
                  "PA",
                  "PR",
                  "RI",
                  "SC",
                  "SD",
                  "TN",
                  "TX",
                  "UT",
                  "VT",
                  "VI",
                  "VA",
                  "WA",
                  "WV",
                  "WI",
                  "WY",
                  "AE",
                  "AA",
                  "AP",
                ]}
                onChange={licenseStateChange}
                value={revise.LICENSE_STATE}
                getOptionLabel={(option) => option}
                style={{ width: 150 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="License State"
                    variant="outlined"
                    autocomplete="off"
                  />
                )}
              />
            </div> */}
            <textarea
              maxlength="1000"
              placeholder="Notes"
              rows="4"
              cols="50"
              onChange={notesChange}
            ></textarea>
          </div>
          <div>
            <button onClick={() => alert("Submit!")} className="submit">
              SUBMIT
            </button>
            <button onClick={closeAdd} className="cancel">
              CANCEL
            </button>
          </div>
        </div>
      </Modal>
    </StyledHRPage>
  );
};

export default HRPage;

// CSV
const formatData = (data) =>
  data.map((d) => ({
    ...d,
    velocifyResolved: d.velocifyResolved.props.checked,
    crm_resolved: d.crm_resolved.props.checked,
  }));
