import React, { useState, useContext, useEffect } from "react";
import { StyledAdminPage } from "./styles";
import Success from "../../Component/Success/Success";
import { Context } from "../../App";
import Modal from "../../Component/Modal/Modal";
import TransferForm from "../../Container/TransferForm/TransferForm";
const AdminPage = ({ ...props }) => {
  const { userInfo, success, permission } = useContext(Context);
  const [modal, setModal] = useState(false);
  const transferClick = (e) => {
    e.preventDefault();
    setModal(true);
  };

  useEffect(() => {
    if (permission !== "USER_HR") {
      props.history.push("/");
    }
  }, [permission]);

  const adminClick = (e) => {
    e.preventDefault();
    props.history.push("/admin");
  };
  return (
    <StyledAdminPage>
      <div className="grid_contain">
        <div className="card">
          <h3>Request from Sung Lee on 06/15/2020</h3>
          <div className="reason">
            <p>Reason: New Hire</p>
          </div>
          <div className="info">
            <p className="info_granular">1FD7W3B69HEC68720</p>

            <p>Michael Bharrat</p>

            <p>Installs</p>

            <p>South Plainfield</p>
          </div>
          <div className="button_container">
            <button className="approve">APPROVE</button>
            <button className="reject">REJECT</button>
          </div>
        </div>
        <div className="card">
          <h3>Request from George Gibson on 06/02/2020</h3>
          <div className="reason">
            <p>Reason:Dept Change</p>
          </div>
          <div className="info">
            <p className="info_granular">1FD7W3B69HEC69076</p>

            <p>Shawn Weber</p>

            <p>Assessments</p>

            <p>South Plainfield</p>
          </div>
          <div className="button_container">
            <button className="approve">APPROVE</button>
            <button className="reject">REJECT</button>
          </div>
        </div>
      </div>
    </StyledAdminPage>
  );
};

export default AdminPage;
