import styled from "styled-components";

export const StyledMainPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  padding-top: 30px;
  padding-bottom: 30px;
  height: auto;
  width: 100%;
  button.mainpage {
    margin: 1em;
    border: none;
    padding: 1.5em;
    font-size: 16px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-weight: bold;
    width: 100%;
    text-transform: uppercase;
  }
  button.mainpage:hover {
    cursor: pointer;
  }

  button.transfer {
    color: white;
    background: rgb(33, 40, 62);
  }
  button.repair {
    color: white;
    background: #878ea4;
  }
  button.inspection {
    color: white;
    background: rgb(33, 40, 62);
  }
`;
