import styled from "styled-components";

export const StyledHead = styled.nav`
  /* height: 8%; */
  background: rgb(33, 40, 62);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  width: 100%;
  .name {
    padding: 0em;
    text-align: start;
    padding-left: 1em;
  }
  .log {
    padding: 0em;
    text-align: end;
    padding-right: 1em;
  }
  .log:hover {
    cursor: pointer;
  }
  .button {
    padding: "1em;";
  }
  .back2search {
    background: #007bff;
    color: white;
    font-weight: bold;
    justify-self: center;
    align-self: center;
    padding: 1em;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    width: 40%;
    font-size: 16px;
    margin-bottom: 1em;
    border: none;
    outline: none;
  }
  .back_button {
    margin: 0.5em;
    border: none;
    outline: none;
    padding: 1em;
    font-size: 16px;
    font-weight: bold;
    border-radius: 7px;
    background-color: #2471a3;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  }
  .back_button:hover {
    cursor: pointer;
  }
  .back2search:hover {
    cursor: pointer;
  }
  .back2search:active {
    background-color: #0056b3;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
`;
