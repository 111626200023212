import React, { useEffect, useContext } from "react";
import { StyledHead, StyledHead2 } from "./styles";

import { Context } from "../../App";
import Logout from "./logout.svg";
import Search from "../../Component/Icons/search.svg";

const Header = ({ history, accountInfo, ...props }) => {
  const { setUserInfo, permission, vehicleResults, setSearchModal } = useContext(
    Context
  );
  useEffect(() => {
    setUserInfo({
      email: accountInfo.account.userName,
      name: accountInfo.account.name,
    });
  }, [accountInfo]);
  const backToSearch = (e) => {
    e.preventDefault();
    history.push("/");
  };
  const logoutClick = (e) => {
    e.preventDefault();
    localStorage.removeItem("msal.idtoken");
    window.location.reload();
  };
  const goBack = (e) => {
    e.preventDefault();

    history.push("/");
  };
  const clickSearch = (e) => {
    e.preventDefault();
    setSearchModal(true);
  };
  let el = (
    <StyledHead>
      <div
        style={{ display: "flex", alignItems: "center", justContent: "center" }}
      >
        {history.location.pathname !== "/" && (
          <button className="back_button" onClick={goBack}>
            BACK
          </button>
        )}
        {permission === "ADMIN_USER" && vehicleResults.length !== 0 && (
          <p
            style={{
              marginLeft: "0.3em",
              background: "white",
              borderRadius: "20px",
              padding: "0.2em",
            }}
            onClick={clickSearch}
          >
            <img className="button" height="40px" src={Search} alt="search" />
          </p>
        )}
      </div>

      <p>
        <b>Fleet Tools</b>
      </p>

      <p className="log" onClick={logoutClick}>
        <img className="button" height="40px" src={Logout} alt="logout" />
      </p>
    </StyledHead>
  );

  return el;
};

export default Header;
