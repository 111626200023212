import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../App";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Select,
  TextField,
  MenuItem,
  Icon,
} from "@material-ui/core";
import { TableStyle } from "./styles";
import { firstCharCap, numberWithCommas } from "../../util";
const moment = require("moment");

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "65vh",
  },
});

const TableComponent = ({ headers, rows, ...props }) => {
  const {
    setInvoiceToggleStatic,
    invoiceToggleStatic,
    setLocalMVR,
    localMVR,
    setVLoad,
  } = useContext(Context);

  // Sortable
  const [sortedItems, setSortedItems] = React.useState([]);
  const [lastSorted, setLastSorted] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("desc");

  useEffect(() => {
    if (!!localMVR && !!localMVR.length) {
      setSortedItems(localMVR);
    }
  }, [localMVR]);

  const sortItems = (name) => {
    setLastSorted(name);

    // Set sort order
    if (
      lastSorted.toLowerCase() === name.toLowerCase() &&
      sortOrder === "desc"
    ) {
      setSortOrder("asc");
    } else {
      setSortOrder("desc");
    }

    // Actually sort the items
    if (
      lastSorted.toLowerCase() === name.toLowerCase() &&
      sortOrder === "desc"
    ) {
      setSortedItems([...sortAlphabetically(sortedItems, name, true)]);
    } else {
      setSortedItems([...sortAlphabetically(sortedItems, name, false)]);
    }
  };

  const hasData = !!sortedItems && sortedItems.length;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  const DateSubmitToggle = (e) => {
    setVLoad(true);
    alert(invoiceToggleStatic);
    if (invoiceToggleStatic === "ASC") {
      setInvoiceToggleStatic("DESC");
      setLocalMVR([
        ...localMVR.sort(
          (a, b) =>
            moment(b.DATE_SUBMITTED, "MM/DD/YYYY").unix() -
            moment(a.DATE_SUBMITTED, "MM/DD/YYYY").unix()
        ),
      ]);
      setVLoad(false);
    } else {
      setInvoiceToggleStatic("ASC");
      setLocalMVR([
        ...localMVR.sort(
          (a, b) =>
            moment(a.DATE_SUBMITTED, "MM/DD/YYYY").unix() -
            moment(b.DATE_SUBMITTED, "MM/DD/YYYY").unix()
        ),
      ]);
      setVLoad(false);
    }
  };

  const classes = useStyles();

  return (
    <TableStyle>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((i) =>
                i === "Date Submitted" ? (
                  <>
                    <TableCell
                      className="head"
                      align="center"
                      onClick={() => sortItems(i.type)}
                    >
                      {i.title}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell
                      className="head"
                      align="center"
                      onClick={() => sortItems(i.type)}
                    >
                      {i.title}
                    </TableCell>
                  </>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((v, x) => {
                // console.log({v})
                return (
                  <TableRow
                    key={x}
                    id={JSON.stringify({
                      FIRST_NAME: v.FIRST_NAME,
                      LAST_NAME: v.LAST_NAME,
                    })}
                    onClick={props.clickRow}
                  >
                    <TableCell className="bigFont" align="center">
                      {firstCharCap(v.LAST_NAME)}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {firstCharCap(v.FIRST_NAME)}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {firstCharCap(v.DEPT)}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {firstCharCap(v.MARKET)}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {`${firstCharCap(v.MANAGER_LAST_NAME)}, ${firstCharCap(
                        v.MANAGER_FIRST_NAME
                      )}`}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {v.DATE_SUBMITTED}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {v.MVR_RESULTS.toUpperCase() === "APPROVED" ? (
                        <p className="approved"> {v.MVR_RESULTS}</p>
                      ) : v.MVR_RESULTS.toUpperCase() === "DENIED" ? (
                        <p className="denied"> {v.MVR_RESULTS}</p>
                      ) : (
                        <p className="pending"> {v.MVR_RESULTS}</p>
                      )}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {v.PAY_ID}
                    </TableCell>
                    {/* <TableCell className="bigFont" align="center">
                      {firstCharCap(v.IS_VALID_LICENSE)}
                    </TableCell> */}
                    {/* <TableCell className="bigFont" align="center">
                      {v.BIRTH_DATE}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {firstCharCap(v.LICENSE_NUMBER)}
                    </TableCell>
                    <TableCell className="bigFont" align="center">
                      {v.LICENSE_STATE}
                    </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableStyle>
  );
};

export default TableComponent;

/**
 * Sorts an array alphabetically.
 * @param {!Array} arr The array to be sorted.
 * @param {string} itemName The key name of the object to sort by.
 * @param {boolean} reverse Whethere or not to just return the array reversed.
 * @return {!Array} The newly sorted array or copy of the array untouched.
 */
export const sortAlphabetically = (arr, itemName, reverse) => {
  console.log({ arr, itemName, reverse });
  if (reverse) return [...arr].reverse();
  return arr && arr.length > 1
    ? arr.sort((a, b) => {
        if (typeof a[itemName] === "string") {
          const aName = `${a[itemName].toLowerCase()}`;
          const bName = `${b[itemName].toLowerCase()}`;
          if (aName < bName) return -1;
          if (aName > bName) return 1;
          return 0;
        }
        if (a[itemName] < b[itemName]) return -1;
        if (a[itemName] > b[itemName]) return 1;
        return 0;
      })
    : arr;
};

// MUI Components
function SortIcon({ name, lastSorted, sortOrder, onIconClick }) {
  const curName = name.toLowerCase();
  const curLastSorted = lastSorted.toLowerCase();
  const isSorting = !!curLastSorted && curLastSorted === curName;
  return (
    <Icon onClick={onIconClick}>
      {isSorting && sortOrder === "asc" ? "^" : "v"}
    </Icon>
  );
}

const map = {};
