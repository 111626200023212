import React, { useEffect, useState, useContext } from "react";
import MainPage from "./Page/MainPage/MainPage";
import AdminPage from "./Page/AdminPage/AdminPage";
import HRPage from "./Page/HRPage/HRPage";
import axios from "axios";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./App.css";
import Header from "./Container/Header/Header";
import { AzureAD } from "react-aad-msal";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./Container/Login/Login";
import GoogleMapReact from "google-map-react";
import Pin from "./Component/Icons/Pin.svg";
import { authProvider } from "./Auth/authConfig";
import AnimatedModal from "./Component/AnimatedModal/AnimatedModal";
import Search from "./Component/Search/Search";
import FloatSideBar from "./Container/FloatSidebar/FloatSidebar";
import SingleVehicle from "./Component/SingleVehicle/SingleVehicle";
import { StyledLoadPage } from "./Container/LoaderHolder/styles";

// //graphql
const Context = React.createContext({});

export { Context };

const PublicRoute = (props) => {
  const { permission } = useContext(Context);
  const { component: Component, ...rest } = props;
  return (
    <AzureAD provider={authProvider}>
      {({ accountInfo }) => {
        return localStorage.getItem("msal.idtoken") && accountInfo ? (
          <Route
            {...rest}
            render={(props) => (
              <>
                <Header history={props.history} accountInfo={accountInfo} />
                {<FloatSideBar permission={permission} />}
                <Component {...props} />
              </>
            )}
          />
        ) : (
          <Route
            {...rest}
            render={(props) => (
              <Login login={authProvider.login} history={props.history} />
            )}
          />
        );
      }}
    </AzureAD>
  );
};
function App() {
  //sideNav values
  const [navValues, setNavValues] = useState({
    master_dash: true,
    inventory: false,
    addVehicle: false,
    accident: false,
    editVehicle: false,
    transfer: false,
    reports: false,
    logs: false,
  });
  //modal for all map
  const [allMap, setAllMap] = useState([]);
  //text to let me know value
  const [searchText, setSearchText] = useState("nothing");
  const [vLoad, setVLoad] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [singleVehicle, setSingleVehicle] = useState([]);
  const [mapModal, setMapModal] = useState(false);
  const [vehicleResults, setVehicleResults] = useState([]);
  const [vehicleLocations, setVehicleLocations] = useState([]);
  const [userInfo, setUserInfo] = useState({ email: "", name: "" });
  const [mvr, setMVR] = useState([]);
  const [localMVR, setLocalMVR] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [success, setSuccess] = useState(false);
  const [valid, setValid] = useState("");
  const [error, setError] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [invoiceToggleStatic, setInvoiceToggleStatic] = useState("ASC");
  //these will be replaced with some api call
  const [markets, setMarkets] = useState([]);
  const [depts, setDepts] = useState([]);
  const [chipData, setChipData] = useState([]);
  const [searched, setSearched] = useState(false);
  const [permission, setPermission] = useState("");
  const [managerEmail, setManagerEmail] = useState([]);
  const [openReport, setOpenReport] = useState("");
  const [termReports, setTermReports] = useState([]);

  const resetNav = () => {
    return { master_dash: false, inventory: false, reports: false };
  };
  //app start
  useEffect(() => {
    if (userInfo.email !== "") {
      //console.log(userInfo.email);
      setVLoad(true);
      axios
        .post(
          `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/authenticate`,
          {
            EMAIL: userInfo.email,
          }
        )
        .then((t) => {
          setPermission(t.data.PERMISSION);

          axios
            .get(
              `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_all`
            )
            .then((v) => {
              console.log("loading in");
              console.log(v.data);
              setDepts(v.data.dept);
              setMarkets(v.data.market);
              setVehicles(v.data.vehicle);
              setManagerEmail(v.data.manager);
              setDrivers([
                ...v.data.drivers,
                {
                  DRIVER_FIRST_NAME: "UNASSIGNED",
                  DRIVER_LAST_NAME: "UNASSIGNED",
                  DRIVER_PAY_ID: "UNASSIGNED",
                },
              ]);
            });
          // url = `http://localhost:8000/get_mvr`;
          axios
            .get(
              `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_mvr`
            )
            .then((x) => {
              setMVR(x.data);
              setVLoad(false);
            });
        })
        .catch((e) => {
          //console.log("permission error");
          setVLoad(false);
          setPermission("ERROR");
        });
    }
  }, [userInfo]);

  useEffect(() => {
    if (valid !== "") {
      NotificationManager.success(``, `${valid}`, 3000);
      setValid("");
    }
    if (error !== "") {
      NotificationManager.error(``, `${error}`, 3000);
      setError("");
    }
  }, [valid, error]);

  return (
    <Context.Provider
      value={{
        userInfo,
        setVehicles,
        setUserInfo,
        depts,
        markets,
        success,
        setSuccess,
        setError,
        setValid,
        vehicles,
        chipData,
        setChipData,
        searched,
        setSearched,
        mvr,
        managerEmail,
        setMVR,
        localMVR,
        setLocalMVR,
        permission,
        drivers,
        invoiceToggleStatic,
        setInvoiceToggleStatic,
        vehicleResults,
        setVehicleResults,
        setVehicleLocations,
        vehicleLocations,
        setMapModal,
        setSingleVehicle,
        singleVehicle,
        filterValues,
        setFilterValues,
        searchModal,
        setSearchModal,
        vLoad,
        setVLoad,
        searchText,
        setSearchText,
        allMap,
        setAllMap,
        navValues,
        setNavValues,
        resetNav,
        openReport,
        setOpenReport,
        termReports,
        setTermReports,
      }}
    >
      <div className="App">
        <BrowserRouter>
          <Switch>
            <PublicRoute path="/" exact component={MainPage} />
            <PublicRoute path="/admin" exact component={AdminPage} />
            <PublicRoute path="/mvr" exact component={HRPage} />
          </Switch>
        </BrowserRouter>
      </div>

      <NotificationContainer />

      <AnimatedModal
        onClose={(e) => setMapModal(false)}
        showModal={mapModal}
        width={"50vw"}
      >
        <SingleVehicle />
      </AnimatedModal>
      <AnimatedModal
        onClose={(e) => setAllMap([])}
        showModal={allMap.length > 0 ? true : false}
        width={"50vw"}
      >
        <div
          style={{
            height: "50vh",
            width: "100%",
          }}
        >
          {allMap.length > 0 && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8",
              }}
              defaultCenter={{
                lat: +allMap.find((i) => i.LAT !== "NO GEOTAB").LAT,
                lng: +allMap.find((i) => i.LNG !== "NO GEOTAB").LNG,
              }}
              defaultZoom={16}
            >
              {allMap.map((i) => {
                if (i.LAT !== "NO GEOTAB") {
                  return (
                    <img
                      src={Pin}
                      alt="pin icon"
                      style={{ height: "50px" }}
                      lat={+i.LAT}
                      lng={+i.LNG}
                    />
                  );
                }
              })}
            </GoogleMapReact>
          )}
        </div>
      </AnimatedModal>

      {/* for searching */}
      <AnimatedModal
        onClose={(e) => setSearchModal(false)}
        showModal={searchModal}
        width={"50vw"}
      >
        <Search />
      </AnimatedModal>
    </Context.Provider>
  );
}

export default App;
