import React, { useEffect } from "react";
import { ModalStyle } from "./styles";
import { motion, AnimatePresence } from "framer-motion";
const AnimatedModal = ({ children, width, onClose, showModal }) => {
  const escFunction = (e) => {
    if (e.keyCode === 27) {
      onClose(e);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    // const data
  }, []);

  const handleClick = (e) => {
    if (e.target.id === "modalBackground") {
      return onClose(e);
    } else {
      return;
    }
  };

  //   const closeModal =
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("mousedown", handleClick, false);
  }, []);

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <ModalStyle id="modalBackground" width={width}>
            <div className="modalMain">{children}</div>
          </ModalStyle>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnimatedModal;
