import React, { useState, useContext } from "react";
import SearchStyle from "./styles";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { Context } from "../../App";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
const Search = ({ ...props }) => {
  const {
    setVehicleResults,
    setValid,
    filterValues,
    setSearchModal,
    setVLoad,
    setSearchText,
  } = useContext(Context);

  const [APIFilter, setAPIFilter] = useState({
    VINS: "",
    DEPT: "",
    MARKET: "",
    DRIVER_FIRST_NAME: "",
    DRIVER_LAST_NAME: "",
  });

  const setText = (obj) => {
    let initial = [];
    if (obj.VINS !== "") {
      initial.push(`VIN: ${obj.VINS}`);
    }
    if (obj.DEPT !== "") {
      initial.push(`DEPT: ${obj.DEPT}`);
    }
    if (obj.MARKET !== "") {
      initial.push(`OFFICE: ${obj.MARKET}`);
    }
    if (obj.DRIVER_FIRST_NAME !== "") {
      initial.push(`DRIVER: ${obj.DRIVER_FIRST_NAME} ${obj.DRIVER_LAST_NAME}`);
    }
    if (initial.length === 0) {
      return "ALL VEHICLES";
    } else {
      return initial.join(",");
    }
  };
  const onSearch = async (e) => {
    e.preventDefault();
    //console.log(APIFilter);
    setVLoad(true);
    try {
      let vehicles = await axios.post(
        `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_vehicles`,
        APIFilter
      );

      //we want statuses as well
      const justVins = vehicles.data.data.map((v) => v.VIN);
      let geo_status = await axios.post(
        `https://ab6sgxl2r9.execute-api.us-east-1.amazonaws.com/latest/get_locations_v2`,
        {
          VIN: justVins,
        }
      );

      //console.log(justVins);
      //console.log(geo_status.data.data);
      let cleaned = [];
      vehicles.data.data.forEach((f) => {
        let found = geo_status.data.data.find((g) => g.VIN === f.VIN);
        if (found) {
          cleaned.push({ ...f, GEO_STATUS: found.IS_DEVICE_COMMUNICATING });
        } else {
          cleaned.push({ ...f, GEO_STATUS: "NO GEOTAB" });
        }
      });
      //console.log("here is cleaned");
      //console.log(cleaned);
      setValid(vehicles.data.message);
      setVehicleResults(cleaned);
      setVLoad(false);
      setSearchText(setText(APIFilter));
      setSearchModal(false);
    } catch (e) {
      //console.log(e);
      setVLoad(false);
      //console.log("broke");
    }
  };
  const VINChange = (e, n) => {
    if (n) {
      setAPIFilter({ ...APIFilter, VINS: n });
    } else {
      setAPIFilter({ ...APIFilter, VINS: "" });
    }
  };
  const DeptChange = (e, n) => {
    if (n) {
      setAPIFilter({ ...APIFilter, DEPT: n });
    } else {
      setAPIFilter({ ...APIFilter, DEPT: "" });
    }
  };
  const MarketChange = (e, n) => {
    if (n) {
      setAPIFilter({ ...APIFilter, MARKET: n });
    } else {
      setAPIFilter({ ...APIFilter, MARKET: "" });
    }
  };
  const NameChange = (e, n) => {
    if (n) {
      setAPIFilter({
        ...APIFilter,
        DRIVER_FIRST_NAME: n.DRIVER_FIRST_NAME,
        DRIVER_LAST_NAME: n.DRIVER_LAST_NAME,
      });
    } else {
      setAPIFilter({
        ...APIFilter,
        DRIVER_FIRST_NAME: "",
        DRIVER_LAST_NAME: "",
      });
    }
  };
  let element = (
    <SearchStyle>
      <p className="header">Search</p>
      <hr className="breaker" />
      <div className="auto">
        <Autocomplete
          id="combo-box-demo"
          options={filterValues.VINS}
          onChange={VINChange}
          getOptionLabel={(option) => `${option}`}
          renderInput={(params) => (
            <TextField {...params} label="All VIN" variant="outlined" />
          )}
        />
        <Autocomplete
          id="combo-box-demo"
          options={filterValues.DEPT}
          onChange={DeptChange}
          getOptionLabel={(option) => `${option}`}
          renderInput={(params) => (
            <TextField {...params} label="All Departments" variant="outlined" />
          )}
        />
      </div>
      <div className="auto2">
        <Autocomplete
          id="combo-box-demo"
          options={filterValues.MARKET}
          onChange={MarketChange}
          getOptionLabel={(option) => `${option}`}
          renderInput={(params) => (
            <TextField {...params} label="All Markets" variant="outlined" />
          )}
        />
        <Autocomplete
          id="combo-box-demo"
          options={filterValues.DRIVER}
          onChange={NameChange}
          getOptionLabel={(option) =>
            `${option.DRIVER_FIRST_NAME} ${option.DRIVER_LAST_NAME}`
          }
          renderInput={(params) => (
            <TextField {...params} label="All Drivers" variant="outlined" />
          )}
        />
      </div>
      <ButtonCustom className="submitButton" onClick={onSearch}>
        Search
      </ButtonCustom>
    </SearchStyle>
  );
  return element;
};

export default Search;
