import styled from "styled-components";

export const StyledAdminPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 100%;
  button.mainpage {
    margin: 1em;
    border: none;
    padding: 1.5em;
    font-size: 16px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-weight: bold;
    width: 300px;
    text-transform: uppercase;
  }
  button.mainpage:hover {
    cursor: pointer;
  }
  .card {
    padding: 1em;
    margin: 1em;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .reason {
    border: solid;
    padding: 0.2em 0.5em 0.2em 0.5em;
    margin: 0.5em;
    border-radius: 7px;
    color: white;
    background-color: #2471a3;
    font-weight: bold;
  }
  .info {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    border: none;
    width: 50%;
    border-radius: 7px;
    font-weight: bold;
    color: white;
    background-color: rgb(33, 40, 62);
  }
  .button_container {
    display: flex;
  }
  h3 {
    text-decoration: underline;
  }
  .approve {
    padding: 1em;
    margin: 0.5em;
    border: none;
    outline: none;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-weight: bold;
    background: #25d366;
    color: white;
  }
  .reject {
    padding: 1em;
    margin: 0.5em;
    border: none;
    outline: none;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-weight: bold;
    background: #ff5a5f;
    color: white;
  }
  .info_granular {
    align-self: end;
    justify-self: start;
  }
  label {
    justify-self: end;
    align-self: end;
  }
  .grid_contain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
  }

  button.transfer {
    color: white;
    background: rgb(33, 40, 62);
  }
  button.repair {
    color: white;
    background: #878ea4;
  }
  button.inspection {
    color: white;
    background: rgb(33, 40, 62);
  }
  @media only screen and (max-width: 1500px) {
    .grid_contain {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 90%;
    }
  }
  @media only screen and (max-width: 1000px) {
    .grid_contain {
      margin-top: 3em;
      display: grid;
      grid-template-columns: 1fr;
      width: 90%;
    }
  }
`;
