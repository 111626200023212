import styled from "styled-components";

const TableStyle = styled.div`
  width: 100%;
  height: 100%;

  .head {
    background-color: #3e4b5a;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }

  .bigFont {
    font-size: 0.9em;
  }
  tr {
    height: 10px;
  }
  .MuiTableCell-root {
    padding: 0.5em;
  }

  svg.progress-icon {
    transform: scale(0.5);
  }
  .MuiTableRow-root:hover {
    cursor: pointer;
  }
  .firstHolder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .approved {
    border: none;
    border-radius: 7px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    background: #25d366;
    color: white;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .denied {
    background: #ff5a5f;
    border: none;
    border-radius: 7px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: white;
  }
  .pending {
    background: #ff9900;
    border: none;
    border-radius: 7px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: white;
  }

  .online {
    background: #25d366;
    border-radius: 7px;
    color: white;
    padding: 0.5em;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  }
  .offline {
    background: #ff5a5f;
    border-radius: 7px;
    color: white;
    padding: 0.5em;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  }

  .missing {
    background: #ffb900;
    border-radius: 7px;
    color: white;
    padding: 0.5em;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
  }

  .is_outcomed {
    border: none;
    padding: 0.2em;
    margin-left: 0.3em;
    border-radius: 30px;
    color: white;
    background-color: #697dc1;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
    font-weight: bold;
    color: whitesmoke;
  }

  .edit:hover {
    cursor: pointer;
  }

  .edit > span:nth-child(1) {
    padding-right: 0.5em;
  }

  .inputdiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .approve {
    padding-left: 0.5em;
    transform: scale(1.5);
  }

  .approve:hover {
    cursor: pointer;
  }
`;
export { TableStyle };
